import { useEffect, useContext, useState, useCallback } from "react";
import FileService from "../../../services/FileService";
import Loading from "../../Shared/Loading";
import SideExplorerItem from "./SideExplorerItem";
import { ExploreContext } from "../../../context/explore-context";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorIcon from "../../Shared/Icons/ErrorIcon";
import { TenantContext } from "../../../context/tenant-context";

const SideExplorer: React.FC<{}> = (props) => {
  const tCtx = useContext(TenantContext);
  const ctx = useContext(ExploreContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [continuationToken, setContinuationToken] = useState<string | null>(
    null,
  );

  // function for loading file session
  const loadMCQsPage = useCallback(() => {
    // this function gets called on retry, so do nothing if there is a loading error set
    if (loadingError) {
      return;
    }
    setIsLoading(true);
    FileService.getSharedMCQs(tCtx.tenantId, continuationToken)
      .then((value) => {
        if (continuationToken)
          ctx.setExploreData([...ctx.exploreData, ...value.data.pageData]);
        else ctx.setExploreData(value.data.pageData);
        setContinuationToken(value.data.nextToken);
        setIsLoading(false);
        setHasMore(value.data.nextToken ? true : false);
      })
      .catch((error) => {
        setLoadingError(true);
        setIsLoading(false);
        setHasMore(false);
      });
  }, [
    continuationToken,
    loadingError,
    setHasMore,
    setContinuationToken,
    setIsLoading,
    setLoadingError,
    ctx.setExploreData,
    ctx.exploreData,
    tCtx.tenantId,
  ]);

  /* load shared on component load */
  useEffect(() => {
    setHasMore(true);
    ctx.setNeedReload(true);
  }, [ctx.setNeedReload]);

  useEffect(() => {
    if (ctx.needReload && !loadingError) {
      setContinuationToken(null);
      loadMCQsPage();
    }
  }, [loadingError, ctx.needReload, loadMCQsPage, setContinuationToken]);

  const retryLoading = () => {
    setLoadingError(false);
    ctx.setNeedReload(true);
  };

  // there is an error
  const renderLoadingError = () => {
    return (
      <div className="my-4">
        <div className="alert flex justify-between">
          <div className="flex flex-row">
            <ErrorIcon />
            <span className="ml-1">Server error</span>
          </div>
          <button
            type="button"
            onClick={retryLoading}
            className="btn shadow-soft-md hover:scale-102 hover:shadow-soft-xs active:opacity-85"
          >
            Retry
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* sidenav  */}
      <aside className="pb-16 lg:pb-0 w-full lg:w-[440px] lg:px-2 lg:mr-4 lg:pb-10 relative h-full inset-y-0 block -translate-x-full flex-wrap items-center justify-between overflow-y-auto border-0 bg-white lg:bg-gray-50 p-0 antialiased transition-transform duration-200 left-0 ">
        <div className="inline-flex lg:hidden pl-4 pt-5 md:pl-8 md:pt-5">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col mt-1 ml-1">
            <span className="text-md sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </div>
        {/*<!-- table 1 -->*/}
        <div className="flex flex-wrap w-full rounded-2xl bg-white">
          <div className="flex-none w-full max-w-full px-0">
            <div className="h-full flex flex-col min-w-0 mb-6 break-words">
              <div className="flex-auto px-0 pt-0 pb-2 mx-0 md:mx-2">
                <div className="grid justify-items-start p-0 overflow-x-hidden ">
                  <div className="text-xl pt-6 px-3 space-y-2 w-full">
                    <h2 className="">Shared</h2>
                    <div className="shadow-soft-xs w-full px-0 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                      <div className="flex flex-row justify-between">
                        <div>MCQs shared in last 30 days:</div>
                        <div
                          className="link text-[#F000B8] ml-1"
                          onClick={() => ctx.setNeedReload(true)}
                        >
                          Reload
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-self-stretch">
                    <InfiniteScroll
                      dataLength={ctx.exploreData.length}
                      next={loadMCQsPage}
                      style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
                      inverse={false}
                      hasMore={hasMore}
                      loader={
                        <div className="mx-4 md:mx-10">
                          <Loading />
                        </div>
                      }
                    >
                      {ctx.exploreData?.map((exploreItem, i) => {
                        return (
                          <SideExplorerItem
                            key={`e-${exploreItem.jobId}`}
                            item={exploreItem}
                          />
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>

      {/* end sidenav */}
    </>
  );
};

export default SideExplorer;
