import NavBar from "../components/Landing/NavBar";
import Hero from "../components/Landing/Hero";
import SectionTitle from "../components/Landing/sectionTitle";
import Benefits from "../components/Landing/benefits";
import Testimonials from "../components/Landing/testimonials";
import Cta from "../components/Landing/cta";
import Footer from "../components/Landing/footer";
import { benefitOne, benefitTwo } from "../components/Landing/Data";
import Examples from "../components/Landing/Examples";

const LandingPage: React.FC<{}> = (props) => {
  return (
    <>
      <NavBar />
      <Hero />
      <SectionTitle pretitle="Benefits" title="Why should you use QuizMD">
        QuizMD uses LLMs to generate high quality USMLE Style MCQs from any
        document. You can use these questions for formative assessments,
        knowledge retention, reinforcement learning, create practice quiz and
        perform evaluation.
      </SectionTitle>
      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />
      <SectionTitle
        pretitle="See Examples"
        title="Take a look at some real examples"
      >
        Not convinced? See the MCQs generated from this
        <a
          href="./assets/docs/flash-cards-4.pdf"
          className="border-b border-rose-500 border-dashed text-rose-500"
        >
          {" "}
          document{" "}
        </a>
        uploaded by a user. Or get started now and try your own documents for
        free.
      </SectionTitle>
      <Examples />
      <SectionTitle
        pretitle="Testimonials"
        title="Here's what others are saying"
      >
        Read what other users are saying about QuizMD
      </SectionTitle>
      <Testimonials />
      <Cta />
      <Footer />
    </>
  );
};

export default LandingPage;
