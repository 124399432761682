import { useEffect, useRef, useState } from "react";
import IconButton from "../../../Shared/IconButton";
import ExitIcon from "../../../Shared/ExitIcon";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";

interface Props {
  open: boolean;
  onClose?: Function | undefined;
  shareUrl: string;
}

const ShareDialog: React.FC<Props> = (props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
    document.body.style.overflow = props.open ? "hidden" : "unset";
    if (props.open) {
      ref.current?.showModal();
    }
  }, [props.open, ref]);

  return (
    <dialog id="shareDialogModal" className="modal" ref={ref}>
      <div className="modal-box max-w-3xl flex flex-row gap-4">
        <div className="h-20 w-20 pt-2">
          <img src="./assets/img/logo.png" />
        </div>
        <div className="h-full w-full py-2 text-slate-600">
          <div className="text-md md:text-lg my-1 sm:pt-2 md:pt-4">
            <span className="font-bold text-lg md:text-xl">Great job!</span>{" "}
            Your MCQs are now shared with others
          </div>
          <div className="hidden form-control py-2 w-full">
            <div className="input-group input-group-md">
              <input
                type="text"
                className="input input-bordered input-md w-full my-auto focus:outline-none"
                value={props.shareUrl}
                readOnly={true}
              />
              <button
                className="btn btn-square btn-md text-slate-600"
                onClick={() => {
                  navigator.clipboard.writeText(props.shareUrl);
                  setCopied(true);
                }}
              >
                <ClipboardDocumentIcon className="h-5" />
              </button>
            </div>
          </div>

          <div className="hidden float-right text-xs h-4 block">
            {copied ? "Copied ✓" : " "}
          </div>
        </div>
        <div className="modal-action">
          <form method="dialog">
            <span className="absolute top-0 right-0 p-4">
              {props.onClose && (
                <IconButton
                  onClick={() => {
                    return props.onClose?.();
                  }}
                >
                  <ExitIcon />
                </IconButton>
              )}
            </span>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default ShareDialog;
