import { Link } from "react-router-dom";
import Footer from "../components/Landing/footer";
import Container from "../components/Landing/container";

const TermsPage: React.FC<{}> = (props) => {
  return (
    <main className="container xl:px-0 mt-0 transition-all duration-200 ease-soft-in-out ps">
      <nav className="px-2 grid grid-cols-2 justify-items-start flex flex-row container py-4 mx-auto">
        <Link to="/" className="h-full flex flex-row items-center gap-1">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col">
            <span className="text-sm sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </Link>
      </nav>
      <Container>
        <section className="mx-auto min-w-[370px]">
          <div className="pt-4">
            <h1 className="text-2xl xl:text-4xl font-bold">Terms of Service</h1>
          </div>
          <div className="pt-4">
            <strong>Last Updated: September 3, 2023</strong>
          </div>
          <div className="flex flex-col py-4 gap-2">
            <div>
              Welcome to <b>QuizMD</b>! These Terms of Service ("Terms") govern
              your use of <b>QuizMD</b>'s services and website (collectively
              referred to as "the Service"). Please read these Terms carefully
              before using the Service.
            </div>
            <div>
              By accessing or using the Service, you agree to be bound by these
              Terms. If you do not agree to these Terms, please do not use the
              Service.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            1. Acceptance of Terms
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              By using the Service, you confirm that you are at least 18 years
              old and capable of forming a binding contract with <b>QuizMD</b>.
              If you are using the Service on behalf of a company or
              organisation, you represent and warrant that you have the
              authority to bind them to these Terms.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            2. Description of Service
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              <b>QuizMD</b> provides a service that creates questions from
              documents provided by users. The questions generated by the
              Service are for educational and informational purposes only and
              should not be considered as professional advice. Users are solely
              responsible for the use of the generated questions and should
              exercise their own judgement when using them.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            3. User Registration
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              To access certain features of the Service, you may be required to
              register for an account. You agree to provide accurate and
              complete information when registering, and you are responsible for
              maintaining the confidentiality of your account credentials.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            4. User Conduct
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>When using the Service, you agree not to:</div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">5. Privacy</h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              Your use of the Service is subject to our{" "}
              <Link to="/privacy">Privacy Policy</Link>, which can be found{" "}
              <Link to="/privacy">here</Link>. By using the Service, you consent
              to the collection and use of your information as described in the
              Privacy Policy.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            6. Intellectual Property
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              <b>QuizMD</b> retains all rights, title, and interest in and to
              the Service, including all intellectual property rights. You may
              not reproduce, modify, distribute, or create derivative works
              based on the Service without our prior written consent.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">7. Termination</h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              <b>QuizMD</b> may terminate or suspend your access to the Service
              at any time and for any reason, without notice. Upon termination,
              you will no longer have access to your account and any data
              associated with it may be deleted.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            8. Disclaimer of Warranties
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              The Service is provided on an "as-is" and "as-available" basis.{" "}
              <b>QuizMD</b> makes no warranties, express or implied, regarding
              the accuracy, reliability, or availability of the Service. Your
              use of the Service is at your own risk.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            9. Limitation of Liability
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              To the extent permitted by law, <b>QuizMD</b> shall not be liable
              for any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from your use of the Service.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            10. Governing Law
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              These Terms are governed by and construed in accordance with the
              laws of Delaware, USA. Any disputes arising from or relating to
              these Terms or the Service shall be subject to the exclusive
              jurisdiction of the courts located in Delaware, USA.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            11. Changes to Terms
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              <b>QuizMD</b> reserves the right to modify or revise these Terms
              at any time. We will notify users of any changes by posting the
              updated Terms on the Service. Your continued use of the Service
              after the effective date of the updated Terms constitutes your
              acceptance of the changes.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">12. Contact Us</h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              If you have any questions or concerns about these Terms or the
              Service, please contact us at{" "}
              <span className="text-blue-500">tos@quizmd.ai</span>.
            </div>
            <div>
              By using <b>QuizMD</b>'s services, you agree to abide by these
              Terms of Service. Thank you for using <b>QuizMD</b>!
            </div>
          </div>
          <div className="flex flex-col pt-10">
            <span className="font-bold text-xl">QuizMD</span>
            <span className="text-blue-500">
              <Link to="/">https://quizmd.ai</Link>
            </span>
            <span>contact@quizmd.ai</span>
          </div>
        </section>
      </Container>
      <Footer />
    </main>
  );
};

export default TermsPage;
