import moment from "moment";
import { useContext, useState, useEffect, useCallback } from "react";
import { TenantContext } from "../../../../context/tenant-context";
import { ArrowDownTrayIcon, TrashIcon } from "@heroicons/react/24/solid";
import FileService from "../../../../services/FileService";
import IconButton from "../../../Shared/IconButton";
import FileTypeIcon from "../../../Shared/FileTypeIcon";
import Deleting from "../Generation/Status/Deleting";
import FileShareButton from "./FileShareButton";
import ExportButton from "../Generation/MCQs/ExportButton";
import { JobStatus } from "../../../../types/JobStatus";

const FileSession: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const session = ctx.getSelectedSession();
  const tenantId = ctx.tenantId;

  const [showAnswer, setShowAnswer] = useState<boolean>(ctx.isSolutionVisible);
  const [slideShow, setSlideShow] = useState<boolean>(ctx.isSlideShowView);
  const hasCompletedJobs = ctx.currentJobData.find(
    (job) => job.status === JobStatus.JOB_STATUS_COMPLETED,
  );
  console.log(hasCompletedJobs);

  useEffect(() => {
    ctx.setSolutionVisibility(showAnswer);
  }, [ctx, ctx.setSolutionVisibility, showAnswer]);

  useEffect(() => {
    ctx.setSlideShowView(slideShow);
  }, [ctx, ctx.setSlideShowView, slideShow]);

  const onDelete = useCallback(() => {
    if (!session) return;
    ctx.deleteSessionItem(session.id);
  }, [session, ctx]);

  const onDownload = useCallback(() => {
    if (!session) return;
    FileService.getSourceFileUrl(tenantId, session.id)
      .then((response) => {
        const url = response.data;
        const link = document.createElement("a");
        link.href = url;
        link.click();
        link.remove();
      })
      .catch(() => {});
  }, [tenantId, session]);

  if (ctx.deleteInProgress) {
    return (
      <div className="w-full px-4 md:px-10 pt-4 gap-y-0 md:gap-y-2">
        <Deleting />
      </div>
    );
  }

  return (
    <>
      {session && (
        <div className="gird flex flex-col sm:flex-row items-center justify-between px-4 md:px-10 pt-4 gap-y-0 md:gap-y-2">
          <div className="flex flex-row items-center space-x-0 sm:space-x-2 pb-2">
            <div className="hidden md:block h-8 w-8 min-w-[40px] lg:h-12 lg:w-12">
              <FileTypeIcon id={session.id} fileName={session.fileName} />
            </div>
            <div className="flex flex-col">
              <h6 className="mb-0 text-md md:text-lg font-bold break-all mb-[1px]">
                {session.fileName}
              </h6>
              <div className="flex lg:flex-row flex-col">
                <div className="mb-0 text-sm text-slate-400 align-middle">
                  <span className="text-slate-500 font-semibold mr-2">
                    Uploaded:
                  </span>
                  <span>
                    {moment(session.timestamp * 1000).format(
                      "h:mm A — Do MMMM YYYY",
                    )}
                  </span>
                  <span className="ml-2">
                    <FileShareButton
                      sessionId={session.id}
                      tenantId={tenantId}
                      sessionShared={session.shared}
                      key={`ss-${session.id}`}
                    />
                  </span>
                  {hasCompletedJobs && (
                    <>
                      <span className="ml-2 border-l pl-2 text-slate-500 font-semibold">
                        Export:
                      </span>
                      <span className="ml-2">
                        <ExportButton
                          key={`ew-${session.id}`}
                          fileId={session.id}
                          format="docx"
                        />
                      </span>
                      <span className="ml-2">
                        <ExportButton
                          key={`ex-${session.id}`}
                          fileId={session.id}
                          format="xlsx"
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-[250px] p-2 border-0 rounded-t-inherit rounded-xl bg-gray-50 gap-x-4 justify-between justify-self-end">
            <div className="flex flex-col min-w-[50px] border-r items-center">
              <IconButton
                onClick={() => {
                  onDownload();
                }}
              >
                <ArrowDownTrayIcon className="h-5 w-5" />
              </IconButton>
              <IconButton
                onClick={() => {
                  onDelete();
                }}
              >
                <TrashIcon className="h-5 w-5" />
              </IconButton>
            </div>
            <div className="flex flex-col">
              <div className="form-control">
                <label className="cursor-pointer label gap-x-2">
                  <span className="label-text">Show Answers</span>
                  <input
                    type="checkbox"
                    className="toggle toggle-secondary"
                    checked={showAnswer}
                    onChange={() => setShowAnswer(!showAnswer)}
                  />
                </label>
              </div>
              <div className="form-control">
                <label className="cursor-pointer label gap-x-2">
                  <span className="label-text">Carousel View</span>
                  <input
                    type="checkbox"
                    className="toggle toggle-secondary"
                    checked={slideShow}
                    onChange={() => setSlideShow(!slideShow)}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FileSession;
