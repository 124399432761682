import {
  AdjustmentsHorizontalIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import Upload from "./Upload";
import TextInput from "./TextInput";
import MCQGenSettings from "./MCQGenSettings";

const NewSession: React.FC<{}> = (props) => {
  return (
    <div className="flex flex-col w-full pb-20 justify-center py-2 px-2 sm:px-4">
      <div className="text-lg md:text-xl p-4 border-b mb-2">
        <div className="flex flex-row justify-between">
          <h1>Generate MCQs</h1>
        </div>
      </div>
      <div className="px-4">
        <div className="bg-gray-50 mb-8 mx-auto border-b rounded-t-inherit rounded-xl">
          <div className="text-xl font-medium">
            <div className="flex flex-row text-lg my-0">
              <div className="flex flex-row">
                <div className="flex flex-col md:flex-row md:items-center justify-between gap-x-2">
                  <div className="flex flex-row justify-start items-center">
                    <div className="flex flex-row mx-2">
                      <div className="h-5 w-5 m-1">
                        <AdjustmentsHorizontalIcon />
                      </div>
                      <h2>Settings</h2>
                    </div>
                    <div className="ml-1 text-sm mr-4">
                      Select types of MCQs{" "}
                      <span className="hidden xl:inline-block">
                        to generate
                      </span>
                    </div>
                  </div>
                  <MCQGenSettings />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 w-full">
        <div className="flex flex-row justify-between items-center mb-4">
          <div className="text-lg my-0">
            <div className="flex flex-row">
              <div className="h-5 w-5 m-1">
                <SparklesIcon />
              </div>
              <div>
                <h2>Upload a file</h2>
                <span className="text-sm">
                  Generate MCQs from uploaded files
                </span>
              </div>
            </div>
          </div>
        </div>
        <Upload />
      </div>
      <div className="divider divider-vertical text-sm py-4 md:py-10">OR</div>
      <div className="px-4 w-full">
        <div className="text-lg mb-4 mt-0">
          <div className="flex flex-row">
            <div className="h-5 w-5 m-1">
              <SparklesIcon />
            </div>
            <div>
              <h2>Use plain text</h2>
              <span className="text-sm">Generate MCQs from text inputs</span>
            </div>
          </div>
        </div>
        <TextInput />
      </div>
    </div>
  );
};

export default NewSession;
