import http from "../common/http-common";
import {
  IFileSessionResponse,
  IGenerationLimitsResponse,
  IShareResponse,
  ISharedMCQsResponse,
} from "../types/FileSessionResponse";
import { IFileSessionCollectionResponse } from "../types/FileSessionResponse";
import {
  IExportItemResponse,
  IJobItemResponse,
  IJobStatusResponse,
  IJobsCollectionResponse,
} from "../types/JobsResponse";
import { IMCQGenerationSettings } from "../types/MCQGeneration";

const uploadFile = (
  tenantId: string,
  file: File,
  settings: IMCQGenerationSettings,
  onUploadProgress: any,
): Promise<IFileSessionResponse> => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("settings", JSON.stringify(settings));

  return http.post(`tenants/${tenantId}/files`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

const generateFromPlainText = (
  tenantId: string,
  plainText: string,
  settings: IMCQGenerationSettings,
): Promise<IFileSessionResponse> => {
  return http.post(`tenants/${tenantId}/files/plain-text`, {
    plain_text: plainText,
    settings: settings,
  });
};

const getJobsByFileSessionId = (
  tenantId: string,
  fileId: string,
): Promise<IJobsCollectionResponse> => {
  return http.get(`tenants/${tenantId}/files/${fileId}`);
};

const getJobById = (
  tenantId: string,
  jobId: string,
): Promise<IJobItemResponse> => {
  return http.get(`tenants/${tenantId}/jobs/${jobId}`);
};

const getJobStatusById = (
  tenantId: string,
  jobId: string,
): Promise<IJobStatusResponse> => {
  return http.get(`tenants/${tenantId}/jobs/${jobId}/status`);
};

const getExportedFileUrl = (
  tenantId: string,
  fileId: string,
  format: string,
): Promise<IExportItemResponse> => {
  return http.get(`tenants/${tenantId}/files/${fileId}/${format}`);
};

const getSourceFileUrl = (
  tenantId: string,
  fileId: string,
): Promise<IExportItemResponse> => {
  return http.get(`tenants/${tenantId}/files/${fileId}/download`);
};

const getFileSessions = (
  tenantId: string,
): Promise<IFileSessionCollectionResponse> => {
  return http.get(`tenants/${tenantId}/files`);
};

const regenerateByFileSessionId = (
  tenantId: string,
  fileId: string,
): Promise<IJobItemResponse> => {
  return http.put(`tenants/${tenantId}/files/${fileId}`);
};

const deleteFileSessionById = (
  tenantId: string,
  fileId: string,
): Promise<IJobsCollectionResponse> => {
  return http.delete(`tenants/${tenantId}/files/${fileId}`);
};

const discardQuestionByIndex = (
  tenantId: string,
  jobId: string,
  index: number,
): Promise<string> => {
  return http.patch(
    `tenants/${tenantId}/jobs/${jobId}/questions/${index}/discard`,
    {
      discard: true,
    },
  );
};

const rateMCQByIdAndIndex = (
  tenantId: string,
  jobId: string,
  index: number,
  liked: boolean,
): Promise<string> => {
  return http.patch(
    `tenants/${tenantId}/jobs/${jobId}/questions/${index}/rating`,
    {
      liked: liked,
    },
  );
};

const postFeedbackMCQByIdAndIndex = (
  tenantId: string,
  jobId: string,
  index: number,
  feedback: string,
): Promise<string> => {
  return http.patch(
    `tenants/${tenantId}/jobs/${jobId}/questions/${index}/feedback`,
    {
      feedback: feedback,
    },
  );
};

const getExamples = (
  tenantId: string,
): Promise<IFileSessionCollectionResponse> => {
  return http.get(`examples`);
};

const loadExampleById = (
  tenantId: string,
  exampleId: string,
): Promise<IFileSessionResponse> => {
  return http.post(`tenants/${tenantId}/examples/${exampleId}`);
};

const shareFileSession = (
  tenantId: string,
  sessionId: string,
  share: boolean,
): Promise<IShareResponse> => {
  return http.patch(`tenants/${tenantId}/files/${sessionId}`, {
    share: share,
  });
};

const getSharedMCQs = (
  tenantId: string,
  continuationToken: string | null,
): Promise<ISharedMCQsResponse> => {
  return http.post(`tenants/${tenantId}/explore`, {
    token: continuationToken,
  });
};

const checkLimitExceeded = (
  tenantId: string,
): Promise<IGenerationLimitsResponse> => {
  return http.get(`tenants/${tenantId}/limits`);
};

const FileService = {
  uploadFile,
  getJobsByFileSessionId,
  getJobById,
  getJobStatusById,
  getExportedFileUrl,
  getSourceFileUrl,
  getFileSessions,
  regenerateByFileSessionId,
  generateFromPlainText,
  deleteFileSessionById,
  rateMCQByIdAndIndex,
  postFeedbackMCQByIdAndIndex,
  loadExampleById,
  getExamples,
  shareFileSession,
  getSharedMCQs,
  discardQuestionByIndex,
  checkLimitExceeded,
};

export default FileService;
