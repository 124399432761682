import MCQItem from "./MCQItem";
import { IMCQItem } from "../../../../../types/JobsResponse";
import moment from "moment";
import { IMCQGenerationSettings } from "../../../../../types/MCQGeneration";
import { useMemo, useState } from "react";
import AllQuestionsDiscardedError from "../Status/Discarded";
import Discarded from "../Status/Discarded";

const MCQsListView: React.FC<{
  jobId: string;
  timestamp: number;
  questions: IMCQItem[];
  settings?: IMCQGenerationSettings | undefined;
}> = (props) => {
  const dateTime = moment(props.timestamp * 1000);
  const [needsRefilter, setNeedsRefilter] = useState(true);
  const filteredQuestions = useMemo(() => {
    setNeedsRefilter(false);
    return props.questions.filter((q) => !q.discarded);
  }, [props.questions, needsRefilter]);

  if (filteredQuestions.length === 0) {
    return <Discarded />;
  }

  return (
    <>
      <div className="mx-2 sm:mx-4 md:mx-10 my-4 px-4 sm:px-4 py-4 border-b rounded-t-inherit rounded-xl bg-gray-50 sm:block">
        <div className="flex flex-col items-start">
          <div className="text-sm font-semibold border-b w-full mb-2 pb-2 flex justify-between">
            <div>
              Generated {filteredQuestions.length}{" "}
              {filteredQuestions.length === 1 ? "MCQ" : "MCQs"}{" "}
              <span className="invisible sm:visible ml-2 text-xs text-slate-400">
                {dateTime.fromNow()}
              </span>
            </div>
            <div>
              {props.settings && props.settings.clinicalCaseMCQs && (
                <span
                  data-tooltip-id="m-tooltip"
                  data-tooltip-content="Generated by the clinical questions generator"
                  className="badge badge-sm badge-outline mx-1"
                >
                  Clinical
                </span>
              )}
              {props.settings && props.settings.factBasedMedicalMCQs && (
                <span
                  data-tooltip-id="m-tooltip"
                  data-tooltip-content="Generated by the fact based questions generator"
                  className="badge badge-sm badge-outline mx-1"
                >
                  Fact based (Simple)
                </span>
              )}
              {props.settings &&
                props.settings.factBasedMedicalMCQsPurposeAligned && (
                  <span
                    data-tooltip-id="m-tooltip"
                    data-tooltip-content="Generated by the fact based (purpose aligned) questions generator"
                    className="badge badge-sm badge-outline mx-1"
                  >
                    Fact based (Aligned)
                  </span>
                )}
            </div>
          </div>
          {filteredQuestions.length > 0 &&
            (() => {
              let prevStem: string | undefined = "";
              return filteredQuestions.map((question: IMCQItem, i) => {
                let jsxElem = (
                  <>
                    {"stem" in question && prevStem !== question["stem"] && (
                      <div className="text-sm md:text-base">
                        <div className="font-bold">Clinical case:</div>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {question.stem}
                        </div>
                      </div>
                    )}
                    {"stem" in question && prevStem === question["stem"] && (
                      <div className="text-sm md:text-base">
                        <div className="font-bold">
                          Continued from previous case:
                        </div>
                      </div>
                    )}
                    <MCQItem
                      key={`q-${props.jobId}-${i}`}
                      index={props.questions.findIndex((q) => q === question)}
                      qNum={i}
                      isSeries={"stem" in question}
                      mcqItem={question}
                      isLast={i === filteredQuestions.length - 1}
                      jobId={props.jobId}
                      showEditControls={true}
                      collectionUpdated={setNeedsRefilter}
                    />
                  </>
                );
                // update the prev stem
                prevStem = "stem" in question ? question["stem"] : "";
                return jsxElem;
              });
            })()}
        </div>
      </div>
    </>
  );
};

export default MCQsListView;
