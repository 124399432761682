import { useState, useEffect, useContext, useCallback } from "react";
import { TenantContext } from "../../../context/tenant-context";
import FileService from "../../../services/FileService";
import { Mixpanel } from "../../../common/mixpanel-common";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import FileTypeIcon from "../../Shared/FileTypeIcon";
import CloseIcon from "../../Shared/Icons/CloseIcon";

const Upload: React.FC<{}> = (props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<File>();
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const ctx = useContext(TenantContext);

  // function for uploading the selected file
  const upload = useCallback(() => {
    setProgressPercentage(0);
    if (!currentFile) return;

    setIsUploading(true);
    setMessage("");
    FileService.uploadFile(
      ctx.tenantId,
      currentFile,
      ctx.mcqGenSettings,
      (event: any) => {
        setProgressPercentage(Math.round((100 * event.loaded) / event.total));
      },
    )
      .then((response) => {
        // add this as a session in the history
        ctx.addHistoryItem(response.data);

        // also set it as the active session
        ctx.setSelectedSessionId(response.data.id);

        // clear the file selection
        setCurrentFile(undefined);

        // tracking
        Mixpanel.track("FileUploaded", {
          FileId: response.data.id,
          FileName: currentFile.name,
          FileSize: currentFile.size,
        });
      })
      .catch((err) => {
        setProgressPercentage(0);

        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the selected file! Please try again.");
        }

        setCurrentFile(undefined);
      });
  }, [ctx, currentFile, setProgressPercentage, setCurrentFile, setMessage]);

  // upload the file after user selection
  useEffect(() => {
    if (!currentFile) {
      return;
    }
  }, [currentFile, setIsUploading, setProgressPercentage, upload]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
    setProgressPercentage(0);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    // Do something with the files
    if (acceptedFiles && acceptedFiles.length > 0) {
      setCurrentFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        ["docx"],
      "application/rtf": [".rtf"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
    },
    noClick: true,
  });

  const renderCurrentFile = useCallback(() => {
    return (
      <div className="flex flex-row text-sm align-middle">
        <div className="flex flex-row border rounded-lg px-2 bg-white hover:border-slate-200 mr-2">
          <div className="m-auto w-7 h-7 mr-2">
            <FileTypeIcon fileName={currentFile!.name} id="123"></FileTypeIcon>
          </div>
          <div className="m-auto text-md font-semibold">
            {currentFile?.name}
          </div>
          <button
            className="w-6 ml-2"
            onClick={() => setCurrentFile(undefined)}
          >
            <CloseIcon></CloseIcon>
          </button>
        </div>
        <button
          className="m-auto bg-gray-200 flex gap-2 h-10 px-4 py-2 font-semibold sm:font-bold border border-slate-300 rounded-lg text-slate-400 enabled:text-slate-700 enabled:hover:border-slate-400 enabled:hover:text-slate-900 enabled:hover:shadow transition duration-150"
          onClick={() => upload()}
        >
          <div className="text-center w-full">Generate</div>
        </button>
      </div>
    );
  }, [currentFile]);

  return (
    <>
      {/*<!-- Upload  -->*/}
      <div className="relative flex p-6 border-b rounded-t-inherit rounded-xl bg-gray-50">
        <div className="flex items-center justify-center w-full">
          {isUploading ? (
            <div className="flex flex-col">
              <span className="text-center">Uploading</span>
              <progress
                className="mt-4 progress progress-secondary w-56"
                value={progressPercentage}
                max="100"
              ></progress>
            </div>
          ) : currentFile ? (
            renderCurrentFile()
          ) : (
            <>
              <input
                type="file"
                onChange={selectFile}
                accept="application/pdf, application/msword, application/rtf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                className="block lg:hidden file-input file-input-bordered file-input-md w-full"
              />
              <label
                htmlFor="dropzone-file"
                className={clsx(
                  { "bg-[#ffa]": isDragActive },
                  "hidden lg:block p-5 flex flex-col items-center justify-center h-full w-full border-2 rounded-lg cursor-pointer border-dashed rounded-t-inherit rounded-xl bg-gray-50",
                )}
                {...getRootProps()}
              >
                <div className="relative h-full w-full flex flex-col items-center justify-center">
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src="./assets/img/upload.png"
                      className="w-12 h-12 mb-4 text-gray-500"
                      alt="upload icon"
                    />
                    <p className="mb-2 text-gray-500">
                      <span className="text-lg font-semibold">
                        Click to upload
                      </span>{" "}
                      or drag & drop
                    </p>
                    {message ? (
                      <p className="text-xs text-red-500 font-bold">
                        {message}
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">
                        Office documents and Pdf files are supported (*.pdf,
                        *.docx, *.pptx, *.rtf)
                      </p>
                    )}
                  </div>
                  <div className="mx-auto text-sm absolute bottom-0">
                    <a
                      className="py-4"
                      href="./assets/docs/terms_and_conditions.pdf"
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    ></a>
                  </div>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  multiple={false}
                  className="hidden"
                  onChange={selectFile}
                  accept="application/pdf"
                  {...getInputProps()}
                />
              </label>
            </>
          )}
        </div>
      </div>
      {/*<!-- end Upload  -->*/}
    </>
  );
};

export default Upload;
