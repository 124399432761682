import { Tooltip } from "react-tooltip";
import Generator from "../components/Generator/Generator";
import ConfirmAndDeleteFile from "../components/Modals/ConfirmAndDeleteFile";
import History from "../components/Generator/History/History";
import { useContext, useEffect, useState } from "react";
import { TenantContext } from "../context/tenant-context";
import { Bars3Icon } from "@heroicons/react/24/solid";
import LimitExceededBanner from "../components/Generator/LimitExceededBanner";
import FileService from "../services/FileService";

const GeneratorPage: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const [isChecked, setIsChecked] = useState(false);

  const checkHandler = () => {
    setIsChecked(!isChecked);
    ctx.setOpenDrawer(!isChecked);
  };

  useEffect(() => {
    setIsChecked(ctx.openDrawer);
  }, [ctx.openDrawer]);

  useEffect(() => {
    setIsChecked(ctx.openDrawer);
  }, [ctx.openDrawer]);

  useEffect(() => {
    ctx.setRecheckLimit(false);
    FileService.checkLimitExceeded(ctx.tenantId)
      .then((value) => {
        ctx.setLimits(value.data);
      })
      .catch(() => {}); // ignore the error
  }, [ctx.tenantId, ctx.currentJobData, ctx.setLimits, ctx.setRecheckLimit]);

  return (
    <main className="ease-soft-in-out h-full w-full rounded-xl transition-all duration-200">
      <Tooltip id="m-tooltip" className="z-[999]" />
      <ConfirmAndDeleteFile />
      <div className="fixed bottom-24 right-10 z-[999] block lg:hidden">
        <button
          className="w-10 h-10 rounded-full bg-white border border-[#F000B8] p-2 text-[#F000B8]"
          onClick={checkHandler}
        >
          <Bars3Icon />
        </button>
      </div>
      {ctx.limitExceeded && <LimitExceededBanner />}
      <div className="l-[40px] mx-auto">
        <div className="drawer lg:drawer-open lg:pr-2 flex w-full">
          <input
            id="my-drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={isChecked}
            onChange={checkHandler}
          />
          <div className="drawer-side h-full z-990">
            <label
              htmlFor="my-drawer invisible"
              className="drawer-overlay"
            ></label>
            <History />
          </div>
          <div className="drawer-content h-full w-full lg:w-[calc(100%-440px)] pt-6">
            <Generator />
          </div>
        </div>
      </div>
    </main>
  );
};

export default GeneratorPage;
