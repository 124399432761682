import moment from "moment";
import { ISharedJobItem } from "../../types/FileSessionResponse";
import FileTypeIcon from "../Shared/FileTypeIcon";

const SharedItemHeader: React.FC<{
  item: ISharedJobItem;
  showTags: boolean;
}> = (props) => {
  return (
    <div className="flex flex-col py-3">
      <div className="flex flex-row items-stretch pr-2 align-middle bg-transparent shadow-transparent w-full">
        <div className="avatar mx-2">
          <div className="w-12 h-12 rounded-full">
            <img src={props.item.sharedBy.picture} />
          </div>
        </div>
        <div className="flex flex-col space-y-1 w-full">
          <div className="flex justify-between">
            <div className="text-md font-bold pl-1">
              {props.item.sharedBy.firstName} {props.item.sharedBy.lastName}
            </div>
            <div className="text-xs mr-2">
              {moment(props.item.sharedAt * 1000).fromNow()}
            </div>
          </div>
          <div className="flex flex-row mb-1">
            <div className="w-6 mr-1">
              <FileTypeIcon
                fileName={props.item.fileName}
                id={props.item.fileId}
                key={props.item.fileId}
              />
            </div>
            <div className="text-sm">{props.item.fileName}</div>
          </div>
        </div>
      </div>
      <div className="pl-2">
        {props.showTags && (
          <div className="flex flex-wrap space-x-1">
            {props.item.tags &&
              props.item.tags.length > 0 &&
              props.item.tags.map((tag, i) => {
                return (
                  <div
                    className="badge badge-ghost text-xs text-slate-700 my-1"
                    key={`tag-${props.item.jobId}-${i}`}
                  >
                    {tag}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SharedItemHeader;
