import { XMarkIcon } from "@heroicons/react/24/solid";
import { useCallback, useContext, useState } from "react";
import { TenantContext } from "../../../../../context/tenant-context";
import FileService from "../../../../../services/FileService";
import clsx from "clsx";

const MCQEditControls: React.FC<{
  jobId: string;
  index: number;
  onDiscarded: () => void;
}> = (props) => {
  const ctx = useContext(TenantContext);
  const [discarding, setDiscarding] = useState(false);
  const [hasError, setHasError] = useState(false);

  const discard = useCallback(() => {
    setDiscarding(true);
    FileService.discardQuestionByIndex(ctx.tenantId, props.jobId, props.index)
      .then(() => {
        props.onDiscarded();
        setDiscarding(false);
      })
      .catch(() => {
        setDiscarding(false);
        setHasError(true);
      });
  }, [
    setDiscarding,
    props.onDiscarded,
    ctx.tenantId,
    props.jobId,
    props.index,
  ]);

  return (
    <div className="flex gap-x-2 h-12 w-12">
      {discarding ? (
        <span className="rounded-full loading loading-spinner loading-lg"></span>
      ) : (
        <a
          data-tooltip-id="m-tooltip"
          data-tooltip-content={hasError ? "Retry Discard" : "Discard"}
          className={clsx("btn btn-circle", {
            "bg-red-100 hover:bg-red-200": hasError,
          })}
          onClick={discard}
        >
          <span className="h-5 w-5">
            {hasError ? <XMarkIcon color="red" /> : <XMarkIcon />}
          </span>
        </a>
      )}
    </div>
  );
};

export default MCQEditControls;
