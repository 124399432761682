import FileErrorIcon from "../../../../Shared/Icons/FileErroIcon";

const NotAccepted: React.FC<{ timestamp: number; message: string }> = (
  props,
) => {
  return (
    <div className="mx-4 md:mx-10 my-4 px-4 py-4 border-0 rounded-t-inherit rounded-xl bg-blue-50 sm:block">
      <div className="flex flex-col">
        <span className="text-sm font-semibold border-b">
          Content not accepted
        </span>
        <div className="my-8">
          <div className="flex flex-row items-center w-full space-x-4">
            <div>
              <FileErrorIcon />
            </div>
            <div className="break-word">
              <p className="">{props.message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAccepted;
