import MCQItem from "./MCQItem";
import { IMCQItem } from "../../../../../types/JobsResponse";
import clsx from "clsx";
import moment from "moment";
import { IMCQGenerationSettings } from "../../../../../types/MCQGeneration";
import { useMemo, useState } from "react";
import Discarded from "../Status/Discarded";

const MCQsCarouselView: React.FC<{
  jobId: string;
  timestamp: number;
  questions: IMCQItem[];
  settings?: IMCQGenerationSettings | undefined;
  showHeader: boolean;
  showEditControls: boolean;
}> = (props) => {
  const dateTime = moment(props.timestamp * 1000);
  const [needsRefilter, setNeedsRefilter] = useState(true);
  const filteredQuestions = useMemo(() => {
    setNeedsRefilter(false);
    return props.questions.filter((q) => !q.discarded);
  }, [props.questions, needsRefilter]);

  if (filteredQuestions.length === 0) {
    return <Discarded />;
  }

  return (
    <>
      <div className="mx-2 sm:mx-4 md:mx-10 my-4 px-4 sm:px-4 py-4 border-b rounded-t-inherit rounded-xl bg-gray-50 sm:block">
        <div className="flex flex-col items-start grid justify-items-stretch">
          {props.showHeader && (
            <div className="text-sm font-semibold border-b w-full mb-2 pb-2 flex justify-between">
              <div>
                Generated {filteredQuestions.length}{" "}
                {filteredQuestions.length === 1 ? "MCQ" : "MCQs"}{" "}
                <span className="invisible sm:visible mx-2 text-xs text-slate-400">
                  {dateTime.fromNow()}
                </span>
              </div>
              <div>
                {props.settings && props.settings.clinicalCaseMCQs && (
                  <span
                    data-tooltip-id="m-tooltip"
                    data-tooltip-content="Generated by the clinical questions generator"
                    className="badge badge-sm badge-outline mx-1"
                  >
                    Clinical
                  </span>
                )}
                {props.settings && props.settings.factBasedMedicalMCQs && (
                  <span
                    data-tooltip-id="m-tooltip"
                    data-tooltip-content="Generated by the fact based questions generator"
                    className="badge badge-sm badge-outline mx-1"
                  >
                    Fact based (Simple)
                  </span>
                )}
                {props.settings &&
                  props.settings.factBasedMedicalMCQsPurposeAligned && (
                    <span
                      data-tooltip-id="m-tooltip"
                      data-tooltip-content="Generated by the fact based (purpose aligned) questions generator"
                      className="badge badge-sm badge-outline mx-1"
                    >
                      Fact based (Aligned)
                    </span>
                  )}
              </div>
            </div>
          )}
          <div className="carousel w-full">
            {filteredQuestions.length > 0 &&
              filteredQuestions.map((question, i) => {
                return (
                  <div
                    id={`s-${props.jobId}-${i}`}
                    key={`s-${props.jobId}-${i}`}
                    style={{ scrollMarginTop: "100px" }}
                    className="carousel-item w-full block"
                  >
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        {"stem" in question && (
                          <div className="text-sm md:text-base">
                            <div className="font-bold">Clinical case:</div>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {question.stem}
                            </div>
                          </div>
                        )}
                        <MCQItem
                          key={`q-${props.jobId}-${i}`}
                          qNum={i}
                          index={props.questions.findIndex(
                            (q) => q === question,
                          )}
                          mcqItem={question}
                          isLast={i === filteredQuestions.length - 1}
                          jobId={props.jobId}
                          showEditControls={props.showEditControls}
                          isSeries={false}
                          collectionUpdated={setNeedsRefilter}
                        />
                      </div>
                      <div>
                        <div className="flex flex-row justify-between relative h-full">
                          <div className={clsx({ invisible: i === 0 })}>
                            <a
                              href={`#s-${props.jobId}-${i - 1}`}
                              data-tooltip-id="m-tooltip"
                              data-tooltip-content="Previous question"
                              className="btn btn-circle"
                            >
                              ❮
                            </a>
                            <span className="text-xs ml-2">PREVIOUS</span>
                          </div>
                          <div className="mt-4 text-sm font-semibold text-slate-500 flex flex-row">
                            <span className="hidden sm:block mr-1">
                              Question{" "}
                            </span>
                            <span>
                              {i + 1} of {filteredQuestions.length}
                            </span>
                          </div>
                          <div
                            className={clsx({
                              invisible: i === filteredQuestions.length - 1,
                            })}
                          >
                            <span className="text-xs mr-2">NEXT</span>
                            <a
                              href={`#s-${props.jobId}-${i + 1}`}
                              className="btn btn-circle"
                              data-tooltip-id="m-tooltip"
                              data-tooltip-content="Next question"
                            >
                              ❯
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MCQsCarouselView;
