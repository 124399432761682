import { useContext } from "react";
import LoginButton from "../Shared/LoginButton";
import { Link } from "react-router-dom";
import { TenantContext } from "../../context/tenant-context";

const NavBar: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);

  return (
    <div className="w-full">
      <nav className="grid grid-cols-2 justify-items-start flex flex-row container py-4 mx-auto px-2">
        <Link to="/" className="h-full flex flex-row items-center gap-1">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col">
            <span className="text-sm sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </Link>
        <div className="justify-self-end">
          <div className="flex flex-row gap-2 sm:gap-4">
            {ctx.isLoggedIn && (
              <Link
                to="/generator"
                className="h-10 flex items-center px-4 py-2 my-auto text-xs sm:text-sm font-semibold sm:font-bold rounded-lg text-white bg-indigo-600 hover:shadow transition duration-150"
              >
                My MCQs
              </Link>
            )}
            <LoginButton />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
