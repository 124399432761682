import { useCallback, useContext, useEffect, useRef, useState } from "react";
import GenerationItem from "./GenerationItem";
import { TenantContext } from "../../../../context/tenant-context";
import { IJobItem } from "../../../../types/JobsResponse";
import FileService from "../../../../services/FileService";
import Loading from "../../../Shared/Loading";
import { Mixpanel } from "../../../../common/mixpanel-common";
import GenerateMore from "./GenerateMore";

const Generation: React.FC<{ fileSessionId: string }> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [jobData, setJobData] = useState<IJobItem[]>([]);
  const ctx = useContext(TenantContext);
  const tenantId = ctx.tenantId;
  const sessionId = ctx.sessionId;
  const selectedSession = ctx.getSelectedSession();

  useEffect(() => {
    ctx.setCurrentJobData(jobData);
  }, [jobData]);

  // function for loading file session
  const loadJobs = useCallback(() => {
    // this function gets called on retry, so do nothing if there is a loading error set
    if (loadingError || !sessionId) {
      return;
    }

    setJobData([]);
    setIsLoading(true);

    FileService.getJobsByFileSessionId(tenantId, sessionId)
      .then((value) => {
        setJobData(value.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setLoadingError(true);
        setIsLoading(false);
      });
  }, [tenantId, sessionId, loadingError]);

  // function for hancling completion of regeneration
  const regenerationCompleted = useCallback(
    (jobItem: IJobItem) => {
      setJobData((prevJobdata) => [...prevJobdata, jobItem]);

      // tracking
      Mixpanel.track("RegeneratedMCQs", {
        FileId: sessionId,
        FileName: selectedSession?.fileName,
      });
    },
    [sessionId, selectedSession, setJobData, ctx.setNeedReload],
  );

  /* load selected file session and its jobs */
  useEffect(() => {
    // call the function
    setLoadingError(false);
    loadJobs();
  }, [sessionId, loadingError, loadJobs, setLoadingError]);

  // used for scroll to bottom
  const itemsEndRef = useRef<HTMLInputElement | null>(null);

  // callback for loading in progress jobs
  const jobUpdated = useCallback(
    (jobItem: IJobItem) => {
      setJobData((prevJobdata) => {
        const foundIndex = prevJobdata.findIndex((x) => x.id === jobItem.id);
        if (foundIndex === -1) {
          return prevJobdata;
        }
        prevJobdata[foundIndex] = jobItem;
        return [...prevJobdata];
      });

      if (selectedSession?.fileName.startsWith("plain-text")) {
        ctx.setSilentReload(true);
        ctx.setNeedReload(true);
      }
    },
    [setJobData, selectedSession, ctx.setNeedReload, ctx.setSilentReload],
  );

  // currently loading, just show the loader
  if (isLoading) {
    return (
      <div className="mx-4 md:mx-10">
        <Loading />
      </div>
    );
  }

  // there is an error
  if (loadingError) {
    return (
      <div className="md:mx-6 my-4 px-4">
        <div className="alert mx-">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-12 w-12 sm:h-6 sm:w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Could not load information from the server</span>
          <button
            type="button"
            onClick={() => {
              setLoadingError(false);
            }}
            className="px-6 py-3 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-white-600 to-gray-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-16 lg:pb-0">
      {jobData.map((jobItem, i) => {
        return (
          <GenerationItem
            key={`j-${jobItem.id}`}
            jobItem={jobItem}
            isFirst={i === 0}
            jobUpdated={jobUpdated}
          />
        );
      })}
      <div ref={itemsEndRef} style={{ scrollMarginTop: "50px" }}></div>
      <GenerateMore onCompleted={regenerationCompleted} visible={true} />
    </div>
  );
};

export default Generation;
