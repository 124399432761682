import {
  BuildingLibraryIcon,
  AcademicCapIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  TrophyIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid";

import { IMCQItem } from "../../types/JobsResponse";

const benefitOne = {
  title: "For Educators",
  desc: "Generate new questions every time without re-purposing old questions, offering fresh perspectives and reinforcing understanding.",
  image: "./assets/img/educators.png",
  bullets: [
    {
      title: "Improve Assessments",
      desc: "Automatically avoid common question-writing mistakes based on Experts guide with USMLE Style questions, so you can be confident that your questions are exam-ready for complex medical topics.",
      icon: <BuildingLibraryIcon />,
    },
    {
      title: "Reduce Effort",
      desc: "Don’t worry about learning how to write quality questions. Create your personalized question bank for targeted review and deeper comprehension. Gain ultimate flexibility by downloading, editing, and sharing questions.",
      icon: <AcademicCapIcon />,
    },
    {
      title: "Learning Objectives",
      desc: "Cover your learning objectives every time with Custom MCQs related to your document. Test what you teach, with detailed explanations turning each question into a mini-lesson.",
      icon: <ClipboardDocumentListIcon />,
    },
  ],
};

const benefitTwo = {
  title: "For Learners",
  desc: "Practice what you need; not whats available. Re-engage with the material by generating new sets of questions, avoiding rote learning.",
  image: "./assets/img/learners.png",
  bullets: [
    {
      title: "Practice MCQs",
      desc: "Generate unlimited MCQs for exam practice and self-assessment. Enhance your understanding with detailed explanations for each correct answer.",
      icon: <ClipboardDocumentCheckIcon />,
    },
    {
      title: "High Quality MCQs",
      desc: "Achieve test-day readiness with questions modeled after the USMLE and other licensing exams, ensuring you're prepared for the actual format and rigor.",
      icon: <TrophyIcon />,
    },
    {
      title: "Bring Your Own Documents",
      desc: "Create your personalized question bank from your own study materials, allowing for targeted review and deeper comprehension.",
      icon: <BookOpenIcon />,
    },
  ],
};

const questions: IMCQItem[] = [
  {
    question:
      "A 11-month-old boy is brought by his parents to the pediatrician due to concerns about his development. They mention the boy is not achieving the developmental milestones typical for his age and he has been slowly losing the skills he previously developed. They have also noticed an enlargement of his liver and spleen. His physical examination reveals coarse facial features and skeletal deformities. Which of the following is the most probable diagnosis?",
    options: {
      A: "Tay-Sachs disease",
      B: "Sandhoff's disease",
      C: "Niemann-Pick disease",
      D: "Fabry's disease",
      E: "Gaucher's disease",
    },
    correctAnswer: "C",
    explanation:
      "The signs and symptoms described in the clinical scenario- developmental delay followed by regression, hepato-splenomegaly, coarse facial features, and skeletal deformities strongly suggest a lysosomal storage disease. Each of these diseases is characterized by the buildup of certain materials in various parts of cells which impairs their normal function. Gaucher's disease typically presents with bone problems and anemia, and Fabrys usually includes peripheral neuropathy, not typically developmental regression. Tay-Sachs and Sandhoff's diseases generally have central nervous system degradation rather than the mentioned visceral and skeletal signs. These diseases are also associated with a \"cherry-red spot\" in the eye due to the buildup of material in the retina which does not appear to be present here. While these conditions can be similar, the most fitting here based on the age and clinical features is Niemann-Pick disease which is characterized by severe developmental delay, regression, hepatosplenomegaly, skeletal abnormalities and neurologic deterioration.",
    liked: true,
  },
  {
    question:
      "A 68-year-old man is presented in the clinic with his daughter. She reports that her father has recently been having difficulty naming familiar objects and sometimes put objects in odd places, like the refrigerator. His medical history includes hypertension and hyperlipidemia. His neurological examination is unremarkable. What is the most likely diagnosis?",
    options: {
      A: "Vascular dementia",
      B: "Frontotemporal dementia",
      C: "Alzheimer's Disease",
      D: "Dementia with Lewy Bodies",
      E: "Cerebral autosomal-dominant arteriopathy",
    },
    correctAnswer: "C",
    explanation:
      "Alzheimer's Disease presents with progressive memory impairment. The clinical presentation is characterized by loss of memory for more recent events, difficulty with language (particularly naming objects), disorientation, and placing objects in unusual places. Given the patient's age and the absence of any significant neurological signs, Alzheimer's is the most likely diagnosis.",
    liked: true,
  },
  {
    question:
      "A 74-years-old woman is brought to the emergency department with new onset of difficulty with calculation, writing, and inability to identify her fingers. A brain CT scan shows a hypodense lesion in one of her brain hemispheres. This condition is most likely localized to which area of the brain?",
    options: {
      A: "Frontal lobe",
      B: "Parietal lobe",
      C: "Occipital lobe",
      D: "Temporal lobe",
      E: "Insula",
    },
    correctAnswer: "B",
    explanation:
      "The patient's symptoms suggest Gerstmann syndrome, which is characterized by agraphia, acalculia, finger agnosia, and left-right disorientation, indicating a dominant parietal lobe dysfunction.",
    liked: true,
  },
  {
    question:
      "A 16-year-old girl is presented to the clinic with recurrent episodes of falls. On examination, she has scoliosis and impaired position and vibration sense. There is an absence of deep tendon reflexes in the lower extremities. Genetic testing showed GAA triplet repeat. What is the likely diagnosis?",
    options: {
      A: "Huntington's disease",
      B: "Fragile X syndrome",
      C: "Friedrich's ataxia",
      D: "Myotonic dystrophy",
      E: "Spinocerebellar ataxia",
    },
    correctAnswer: "C",
    explanation:
      "Friedrich's ataxia is a neurodegenerative disorder that is characterized by autosomal recessive inheritance, and it is caused by the GAA triplet repeat expansion on chromosome 9. The clinical features include progressive gait ataxia, areflexia in the lower extremities, and a positive Babinski sign, which are noted in this patient.",
    liked: true,
  },
  {
    question:
      "A 6-month-old baby boy starts having multiple episodes of clonic seizures that stop by the time he is 6-months old. Genetic testing reveals mutation on chromosome 20. What is likely diagnosis?",
    options: {
      A: "Juvenile Absence Epilepsy",
      B: "West syndrome",
      C: "Benign familial neonatal convulsions (BFNC)",
      D: "Lennox-Gastaut syndrome",
      E: "Early infantile epileptic encephalopathy",
    },
    correctAnswer: "C",
    explanation:
      "Benign familial neonatal convulsions is a genetic epilepsy syndrome characterized by the onset of frequent, brief seizures within the first week of life. These seizures quickly cease by six months of age and the infant's development remains normal. This is typically linked to a mutation on chromosome 20, which is the KCNQ2 gene.",
    liked: true,
  },
  {
    question:
      'A 17-year-old girl is brought to the emergency room because she has been experiencing recurrent seizures, frequent falls, difficulty with coordination and balance. Her medical history indicates that she has been progressively losing vision. Family history reveals that her older sister died at an early age due to a "neurologic disease", but parents are unsure about details. Which of the following is the most probable diagnosis?',
    options: {
      A: "Mucopolysaccharidosis",
      B: "Glycoproteinosis",
      C: "Sphingolipidosis",
      D: "Neuronal ceroid lipofuscinosis",
      E: "Lysosomal transport disease",
    },
    correctAnswer: "D",
    explanation:
      "Given the description, the symptoms faced by the patient such as vision loss, seizures, coordination problems, and frequent falls, point towards progressive mental and motor retardation – a characteristic feature of neuronal ceroid lipofuscinosis (NCL). NCLs are a group of neurodegenerative disorders characterized by the accumulation of lipopigment in body tissues. Lipopigmments are made up of fats and proteins and their buildup in nerve cells leads to the symptoms observed. The condition is typically typified by progressive loss of vision and motor skills, mental deterioration, seizures, and premature death.",
    liked: true,
  },
];

export { benefitOne, benefitTwo, questions };
