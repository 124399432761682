export enum JobStatus {
  JOB_STATUS_COMPLETED = "Completed",
  JOB_STATUS_FAILED = "Failed",
  JOB_STATUS_PENDING = "Pending",
  JOB_STATUS_INPROGRESS = "InProgress",
  JOB_STATUS_CANCELED = "Cancelled",
  JOB_STATUS_NOT_ACCEPTED = "NotAccepted",
  JOB_STATUS_DISCARDED = "Discarded",
  JOB_STATUS_LIMIT_EXCEEDED = "LimitExceeded",
}
