import MCQsCarouselView from "../Generator/Session/Generation/MCQs/MCQsCarouselView";
import Container from "./container";
import { questions } from "./Data";

const Examples: React.FC<{}> = (props) => {
  return (
    <Container>
      <div className="w-full mx-auto overflow-hidden lg:mb-10 rounded-2xl">
        <div className="mockup-window border bg-base-300">
          <div className="flex justify-center bg-gray-50">
            <MCQsCarouselView
              jobId={"1"}
              timestamp={1693658760}
              questions={questions}
              showHeader={false}
              showEditControls={false}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Examples;
