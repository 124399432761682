import moment from "moment";
import ErrorIcon from "../../../../Shared/Icons/ErrorIcon";
const Failed: React.FC<{ timestamp: number }> = (props) => {
  const dateTime = moment(props.timestamp * 1000);

  return (
    <div className="md:mx-6 my-4 px-4">
      <div className="alert alert-error mx-">
        <ErrorIcon />
        <span>Failed to generate MCQs</span>
        <span className="hidden sm:block text-sm font-semibold">
          Requested:
        </span>
        <span className="text-sm">
          {dateTime.format("h:mm A — Do MMMM YYYY")}
        </span>
      </div>
    </div>
  );
};

export default Failed;
