import clsx from "clsx";
import { useCallback, useState } from "react";
import { IMCQItem } from "../../../types/JobsResponse";
import Tag from "../../Shared/Tag";

const MCQItem: React.FC<{
  mcqItem: IMCQItem;
  index: number;
  isLast: boolean;
  isSeries?: boolean;
}> = (props) => {
  const [checked, setChecked] = useState(false);
  const [isSolutionVisible, setIsSolutionVisible] = useState(false);

  const toggleExp = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const renderDifficultyLevel = () => {
    switch (props.mcqItem.difficultyLevel) {
      case "Hard":
        return (
          <Tag
            tooltip="Difficulty:"
            text="Hard"
            bgColor="#F21B3F"
            textColor="white"
          />
        );
      case "Medium":
        return (
          <Tag
            tooltip="Difficulty:"
            text="Medium"
            bgColor="#EC9A29"
            textColor="white"
          />
        );
      case "Easy":
        return (
          <Tag
            tooltip="Difficulty:"
            text="Easy"
            bgColor="#6AB547"
            textColor="white"
          />
        );
    }
  };

  const renderBloomClassification = () => {
    return (
      <Tag
        prefix="Bloom:"
        tooltip="Bloom Taxonomy:"
        showPrefix={true}
        text={props.mcqItem.bloomClassification!}
        bgColor="#1CB0F6"
        textColor="white"
      />
    );
  };

  return (
    <div className={clsx("space-y-4 py-2 text-sm md:text-base w-full")}>
      <div className="flex flex-row items-center">
        <div className="font-semibold mr-2">
          <h6>Question {props.index + 1}</h6>
        </div>
        <div className="flex flex-row gap-x-2">
          {props.mcqItem.difficultyLevel && renderDifficultyLevel()}
          {props.mcqItem.bloomClassification && renderBloomClassification()}
        </div>
      </div>
      <div style={{ whiteSpace: "pre-wrap" }}>{props.mcqItem.question}</div>
      {props.mcqItem.options && (
        <div className="ml-2 space-y-1">
          <div>
            <span className="font-semibold mr-2">A.</span>
            {props.mcqItem.options.A}
          </div>
          <div>
            <span className="font-semibold mr-2">B.</span>
            {props.mcqItem.options.B}
          </div>
          <div>
            <span className="font-semibold mr-2">C.</span>
            {props.mcqItem.options.C}
          </div>
          <div>
            <span className="font-semibold mr-2">D.</span>
            {props.mcqItem.options.D}
          </div>
          <div>
            <span className="font-semibold mr-2">E.</span>
            {props.mcqItem.options.E}
          </div>
        </div>
      )}
      <div className="">
        <label className={isSolutionVisible ? "" : "swap"}>
          <input
            type="checkbox"
            onChange={toggleExp}
            className={isSolutionVisible ? "hidden" : ""}
          />
          <div className="swap-on space-y-2 border rounded-xl p-4 bg-white">
            <div className="font-semibold">
              <div className="flex flex-row">
                <div className="mr-2">Answer:</div>
                <div className="">{props.mcqItem.correctAnswer}</div>
              </div>
            </div>
            <div>
              <div className="font-semibold mr-1">Explanation:</div>
              <div>
                {(isSolutionVisible || checked) && props.mcqItem.explanation}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              { hidden: isSolutionVisible ?? false },
              "swap-off border rounded-xl lshaded grid justify-items-center flex items-center",
            )}
          >
            <div className="flex align-self-center text-[#F000B8] text-sm font-semibold px-4">
              CLICK TO REVEAL ANSWER
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default MCQItem;
