import { useContext } from "react";
import { TenantContext } from "../../context/tenant-context";
import Session from "./Session/Session";
import NewSession from "./NewSession/NewSession";

const Generator: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);

  return (
    <div className="relative ease-nav-brand fixed inset-y-0 block w-full h-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 bg-white p-0 antialiased shadow-soft-md transition-transform duration-200 left-440 translate-x-0 bg-white">
      <div className="flex flex-col m-auto">
        {ctx.sessionId ? <Session /> : <NewSession />}
      </div>
    </div>
  );
};

export default Generator;
