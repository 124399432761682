interface Props {
  children: React.ReactNode;
  type?: "submit" | "button" | "reset";
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
}
export default function Button(props: Props) {
  const { type = "button", children, onClick, className = "" } = props;
  return (
    <button
      className={`${className} px-6 py-3 mt-6 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl hover:scale-102 hover:shadow-soft-xs active:opacity-85`}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
