import { IGenerationLimits } from "../../types/FileSessionResponse";

const CreditCounter: React.FC<{ limits: IGenerationLimits }> = (props) => {
  const remainingCredits = Math.max(
    0,
    props.limits.generationLimit - props.limits.generationCount,
  );
  return (
    <div
      className="flex h-10 align-middle px-2"
      data-tooltip-id="m-tooltip"
      data-tooltip-content="Remaining free credits"
    >
      <div className="flex align-middle h-full p-1 rounded-bl-lg rounded-tl-lg font-mono text-xl border bg-gray-200 lshaded">
        🪙
      </div>
      <div className="h-full p-2 rounded-br-lg rounded-tr-lg border font-mono text-md font-semibold text-[#F000B8] bg-white font-semibold text-md">
        <span>{remainingCredits}</span>
      </div>
    </div>
  );
};

export default CreditCounter;
