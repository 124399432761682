import { Link } from "react-router-dom";
import Footer from "../components/Landing/footer";
import Container from "../components/Landing/container";

const AboutPage: React.FC<{}> = (props) => {
  return (
    <main className="container xl:px-0 mt-0 transition-all duration-200 ease-soft-in-out ps">
      <nav className="px-2 grid grid-cols-2 justify-items-start flex flex-row container py-4 mx-auto">
        <Link to="/" className="h-full flex flex-row items-center gap-1">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col">
            <span className="text-sm sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </Link>
      </nav>
      <Container>
        <section className="mx-auto min-w-[370px]">
          <div className="pt-4">
            <h1 className="text-2xl xl:text-4xl font-bold">About</h1>
          </div>
          <div className="pt-4">
            Quiz MD, an AI Copilot for medical Questions, has been trained to
            generate USMLE style multiple-choice questions based on the
            information in any document. The questions follow the standard style
            of Stem, lead, 5 options and has a brief explanation on the right
            answer choice. Questions are downloadable as a word document and
            editable.
          </div>
          <div className="pt-4">
            <b>Educators</b> can use the questions to evaluate their learning
            objectives for lectures, create formative assessments and practice
            questions, get the best questions to use for your students, improve
            on your lecture from questions generated and re-generate new
            questions every time.
          </div>
          <div className="pt-4">
            <b>Learners</b> can use questions to practice for exams, reinforce
            and assess their learning, focus on areas of weakness and difficult
            topics, use their handouts and notes to practice questions and share
            questions for group learning and create competition.
          </div>
          <div className="text-xl pt-6 pb-2 font-semibold">
            Current limitations
          </div>
          <div>
            Currently only text based pdf documents are recognized. if you have
            saved PDFs which are images mostly then they wont be read after
            uploading and will give generation error. Some sample documents are
            added to website to try it out. If you plan to try it on something
            in print (like a book or handout) then when scanning (or using phone
            to scan with an app like scanner pro) make sure to turn OCR on so
            that it saves it as text when scanned.
          </div>
        </section>
      </Container>
      <Footer />
    </main>
  );
};

export default AboutPage;
