import { useCallback, useContext, useEffect, useState } from "react";
import { TenantContext } from "../../context/tenant-context";
import ConfirmDialog from "../Shared/Confirm";
import FileService from "../../services/FileService";

const ConfirmAndDeleteFile: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);

  const tenantId = ctx.tenantId;
  const sessionId = ctx.sessionId;
  const deletionRequested = ctx.deletionRequested;
  const currentSession = ctx.getSelectedSession();

  const onSessionItemDeleted = ctx.onSessionItemDeleted;
  const onSessionItemDeletionClosed = ctx.onSessionItemDeletionClosed;
  const setDeleteInProgress = ctx.setDeleteInProgress;

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const deleteItem = useCallback(() => {
    if (!sessionId) {
      return;
    }

    setDeleteInProgress(true);
    FileService.deleteFileSessionById(tenantId, sessionId)
      .then(() => {
        onSessionItemDeleted();
        setDeleteInProgress(false);
      })
      .catch((err) => {
        setDeleteInProgress(false);
      });
  }, [tenantId, sessionId, onSessionItemDeleted, setDeleteInProgress]);

  // open or close the dialog based on the context
  useEffect(() => {
    setDeleteConfirmOpen(deletionRequested);
  }, [deletionRequested]);

  return (
    <div className="fixed z-[999] bg-black">
      <ConfirmDialog
        title="Delete file?"
        open={deleteConfirmOpen}
        onClose={onSessionItemDeletionClosed}
        onConfirm={deleteItem}
      >
        Are you sure you want to delete
        <span className="mx-auto text-base font-semibold">
          {" "}
          "{currentSession?.fileName}"{" "}
        </span>
        and its generated MCQs?
      </ConfirmDialog>
    </div>
  );
};

export default ConfirmAndDeleteFile;
