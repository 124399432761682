import { TypeAnimation } from "react-type-animation";
import Container from "./container";
import { Link } from "react-router-dom";

const Hero: React.FC<{}> = (props) => {
  return (
    <>
      <Container className="flex flex-wrap ">
        <div className="flex items-center w-full lg:w-1/2">
          <div className="max-w-2xl mb-8">
            <span className="leading-10 text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-800 xl:leading-tight xl:text-5xl xl:leading-tight dark:text-white">
              Generate USMLE Style
            </span>
            <span className="leading-10 text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-800 xl:leading-tight xl:text-5xl xl:leading-tight dark:text-white">
              <TypeAnimation
                sequence={[
                  "MCQs from Lectures",
                  1000,
                  "MCQs from Presentations",
                  1000,
                  "MCQs from Articles",
                  1000,
                  "MCQs from Notes",
                  1000,
                  "MCQs from Patient H&Ps",
                  1000,
                  "MCQs from Grand Rounds",
                  1000,
                  "MCQs from Book Chapters",
                  1000,
                  "MCQs from Posters",
                  1000,
                ]}
                wrapper="span"
                speed={50}
                style={{ display: "inline-block" }}
                repeat={Infinity}
              />
            </span>
            <p className="pb-4 lg:py-5 text-md md:text-lg lg:text-xl leading-normal text-gray-500  dark:text-gray-300">
              Generate unlimited USMLE Style questions from your own documents
              for learning, practice and assessments.
            </p>
            <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row">
              <Link
                to="/generator"
                className="px-5 py-3 text-lg font-medium text-center text-white bg-indigo-600 rounded-md"
              >
                Generate Now
              </Link>
            </div>
            <div className="ml-2">
              <span className="text-xs">No Credit Card Required</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center w-full lg:w-1/2">
          <div className="">
            <img
              src="./assets/img/hero.png"
              width="616"
              height="617"
              className={"object-cover"}
              loading="eager"
              placeholder="blur"
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Hero;
