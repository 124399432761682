import React from "react";
import Container from "./container";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <Container>
      <div className="flex flex-wrap items-center justify-between w-full max-w-4xl gap-5 mx-auto text-white bg-indigo-600 px-7 py-4 lg:px-12 lg:py-8 lg:flex-nowrap rounded-xl">
        <div className="flex-grow text-center lg:text-left">
          <p className="mt-2 font-medium text-white text-opacity-90 lg:text-xl">
            Try your own documents. Free for a limited time.
          </p>
        </div>
        <div className="flex-shrink-0 w-full text-center lg:w-auto">
          <Link
            to="/generator"
            target="_blank"
            rel="noopener"
            className="inline-block py-3 mx-auto text-lg font-medium text-center text-indigo-600 bg-white rounded-md px-7 lg:px-10 lg:py-5 "
          >
            Generate Now
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Cta;
