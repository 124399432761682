import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
const LimitExceeded: React.FC<{}> = (props) => {
  return (
    <div className="md:mx-6 my-4 px-4">
      <div className="alert alert-warning mx-">
        <span>
          <ExclamationTriangleIcon height={24} width={24} />
        </span>
        <span>Limit exceeded for Free usage</span>
        <span className="text-sm">Please contact:</span>
        <span className="text-sm font-bold">billing@quizmd.ai</span>
      </div>
    </div>
  );
};

export default LimitExceeded;
