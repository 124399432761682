import Container from "./container";
import { Link, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";

export default function Footer() {
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="relative">
      <Container className="border-t max-w-screen-2xl">
        <div className="flex flex-col lg:flex-row justify-between w-full gap-10 px-2 md:px-10 pt-10 mx-auto mt-5 border-gray-100 dark:border-trueGray-700">
          <div className="flex flex-row justify-center lg:justify-start">
            <img
              className="h-20 w-20 opacity-80 -mt-2 mr-2"
              src="./assets/img/logo.svg"
            />
            <div className="max-w-md text-gray-500 dark:text-gray-400">
              QuizMD is an AI Copilot for medical Questions that has been
              trained to generate USMLE style multiple-choice questions based on
              the information in any document.
            </div>
          </div>
          <div className="flex justify-evenly min-w-xl md:min-w-[500px] text-center">
            <Link
              to="/"
              className="border-r my-auto w-full hover:bg-gray-200 px-4 py-2 text-blue-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-trueGray-700"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="border-r my-auto w-full hover:bg-gray-200 px-4 py-2 text-blue-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-trueGray-700"
            >
              About
            </Link>
            <Link
              to="/terms"
              className="border-r my-auto w-full hover:bg-gray-200 px-4 py-2 text-blue-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-trueGray-700"
            >
              Terms
            </Link>
            <Link
              to="/privacy"
              className="w-full my-auto hover:bg-gray-200 px-4 py-2 text-blue-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-trueGray-700"
            >
              Privacy
            </Link>
          </div>
        </div>
        <div className="my-10 text-sm text-center text-gray-600 dark:text-gray-400">
          Copyright © {new Date().getFullYear()} — QuizMD Limited
        </div>
      </Container>
    </div>
  );
}
