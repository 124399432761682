import { useCallback, useContext } from "react";
import { TenantContext } from "../../../../context/tenant-context";
import MCQsListView from "./MCQs/MCQsListView";
import { IJobItem } from "../../../../types/JobsResponse";
import { JobStatus } from "../../../../types/JobStatus";
import MCQsCarouselView from "./MCQs/MCQsCarouselView";
import InProgress from "./Status/InProgress";
import Failed from "./Status/Failed";
import moment from "moment";
import Canceled from "./Status/Canceled";
import NotAccepted from "./Status/NotAccepted";
import Discarded from "./Status/Discarded";
import LimitExceeded from "./Status/LimitExceeded";

//represents a generation job
const GenerationItem: React.FC<{
  isFirst?: boolean;
  jobItem: IJobItem;
  jobUpdated: (jobItem: IJobItem) => void;
}> = (props) => {
  const ctx = useContext(TenantContext);
  const selectedSession = ctx.getSelectedSession();
  const dateTime = moment(props.jobItem.timestamp * 1000);
  const isSlideShowView = ctx.isSlideShowView;

  const renderByStatus = useCallback(
    (status: string) => {
      switch (status) {
        case JobStatus.JOB_STATUS_COMPLETED:
          return isSlideShowView ? (
            <MCQsCarouselView
              jobId={props.jobItem.id}
              timestamp={props.jobItem.timestamp}
              questions={props.jobItem.questions}
              settings={selectedSession?.settings}
              showHeader={true}
              showEditControls={true}
            />
          ) : (
            <MCQsListView
              jobId={props.jobItem.id}
              timestamp={props.jobItem.timestamp}
              questions={props.jobItem.questions}
              settings={selectedSession?.settings}
            />
          );

        case JobStatus.JOB_STATUS_DISCARDED:
          return <Discarded />;

        case JobStatus.JOB_STATUS_LIMIT_EXCEEDED:
          return <LimitExceeded />;

        case JobStatus.JOB_STATUS_NOT_ACCEPTED:
          return (
            <NotAccepted
              timestamp={props.jobItem.timestamp}
              message={props.jobItem.statusMessage}
            />
          );

        case JobStatus.JOB_STATUS_FAILED:
          return <Failed timestamp={props.jobItem.timestamp} />;

        case JobStatus.JOB_STATUS_CANCELED:
          return <Canceled timestamp={props.jobItem.timestamp} />;

        default:
          return (
            <InProgress
              jobItem={props.jobItem}
              timestamp={props.jobItem.timestamp}
              onProgessEnded={props.jobUpdated}
            />
          );
      }
    },
    [isSlideShowView, props.jobItem, props.jobUpdated, ctx, selectedSession],
  );

  return (
    <>
      {!props.isFirst && (
        <div className="grid my-10 items-centered border-b">
          <div className="text-xs font-semibold justify-self-center px-2 mb-[-8px] bg-white">
            REGENERATED {dateTime.format("h:mm A — Do MMMM YYYY")}
          </div>
        </div>
      )}
      {renderByStatus(props.jobItem.status)}
    </>
  );
};

export default GenerationItem;
