import { useContext } from "react";
import MCQExplorer from "./MCQs/MCQExplorer";
import { ExploreContext } from "../../context/explore-context";
import clsx from "clsx";

const Explorer: React.FC<{}> = (props) => {
  const ctx = useContext(ExploreContext);

  return (
    <div className="pb-16 lg:pb-0">
      <div className="relative ease-nav-brand fixed inset-y-0 block w-full h-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 bg-white p-0 antialiased shadow-soft-md transition-transform duration-200 left-440 translate-x-0 bg-white">
        <div
          className={clsx("flex flex-col m-auto", {
            "hidden lg:block": ctx.openDrawer,
          })}
        >
          <MCQExplorer />
        </div>
      </div>
    </div>
  );
};

export default Explorer;
