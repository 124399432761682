const FileTypeIcon: React.FC<{
  id: string;
  fileName: string;
}> = (props) => {
  // get file extension
  const fileExt = props.fileName.split(".").pop();
  const fileIconClass =
    fileExt === undefined ? "bg-file" : `bg-${fileExt.toLowerCase()}`;

  return (
    <div
      className={`h-full w-full ${fileIconClass} text-sm transition-all duration-200 ease-soft-in-out`}
    />
  );
};

export default FileTypeIcon;
