import ExitIcon from "./ExitIcon";
import IconButton from "./IconButton";
interface Props {
  children: React.ReactNode;
  open: boolean;
  onClose?: Function | undefined;
}
const Dialog: React.FC<Props> = (props) => {
  if (!props.open) {
    return <></>;
  }
  return (
    <div className="relative z-[999] w-full">
      <div
        style={{ backgroundColor: "hsla(0,0%,6%,.6)" }}
        className="fixed w-full h-[100vh] top-0 left-0"
      ></div>
      <div className="fixed inset-0 overflow-auto flex drop-shadow-md">
        <div className="pt-14 pb-6 px-6 relative bg-white w-full max-w-4xl m-auto flex-col flex rounded-lg">
          <div>{props.children}</div>
          <span className="absolute top-0 right-0 p-4">
            {" "}
            {props.onClose && (
              <IconButton
                onClick={() => {
                  return props.onClose?.();
                }}
              >
                <ExitIcon />
              </IconButton>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
