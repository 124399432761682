import { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { TenantContext } from "../context/tenant-context";

const Protected: React.FC<{ children: ReactElement }> = (props) => {
  const ctx = useContext(TenantContext);
  if (!ctx.isLoggedIn) {
    // user is not logged in, ask to login
    return <Navigate to="/login" replace />;
  }
  // return the protected components
  return props.children;
};

export default Protected;
