import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

// allow cookies for credentials
axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

// redirect to login on unauthorized
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const win: Window = window;
      win.location = "/login";
    }
  },
);

export default instance;
