import { Link } from "react-router-dom";
import Container from "../components/Landing/container";
import Footer from "../components/Landing/footer";

const PrivacyPage: React.FC<{}> = (props) => {
  return (
    <main className="container xl:px-0 mt-0 transition-all duration-200 ease-soft-in-out ps">
      <nav className="px-2 grid grid-cols-2 justify-items-start flex flex-row container py-4 mx-auto">
        <Link to="/" className="h-full flex flex-row items-center gap-1">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col">
            <span className="text-sm sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </Link>
      </nav>
      <Container>
        <section className="mx-auto min-w-[370px]">
          <div className="pt-4">
            <h1 className="text-2xl xl:text-4xl font-bold">Privacy Policy</h1>
          </div>
          <div className="pt-4">
            <strong>Last Updated: September 3, 2023</strong>
          </div>
          <div className="flex flex-col py-4 gap-2">
            <div>
              Thank you for choosing <b>QuizMD</b>! This Privacy Policy outlines
              how we collect, use, and protect your personal information when
              you use our service, which generates questions from documents you
              provide. Your privacy is important to us, and we are committed to
              safeguarding your personal data.
            </div>
            <div>
              By using <b>QuizMD</b>, you agree to the practices described in
              this Privacy Policy. If you do not agree with our practices,
              please do not use our service.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            1. Information We Collect
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <h3 className="text-lg xl:text-xl font-semibold">
              1.1. Information You Provide
            </h3>
            <div>
              <span className="font-semibold">User Data:</span> When you create
              an account or use our service, we may collect personal information
              such as your name, email address, and other contact details.
            </div>
            <div>
              <span className="font-semibold">Uploaded Documents:</span> To
              generate questions, you may upload documents. Please note that it
              is your responsibility to ensure that the documents you upload do
              not contain copyrighted material, and we do not access, store, or
              use the content of your documents for any purpose other than
              generating questions.
            </div>
          </div>
          <div className="flex flex-col py-4 gap-2">
            <h3 className="text-lg xl:text-xl font-semibold">
              1.2. Automatically Collected Information
            </h3>
            <div>
              <span className="font-semibold">Usage Data:</span> We collect
              information about how you interact with <b>QuizMD</b>, including
              your IP address, browser type, and pages you visit. We use this
              information to improve our service and ensure its security.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            2. Use of Your Information
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              We use the information we collect for the following purposes:
            </div>
            <div>
              <span className="font-semibold">Providing the Service:</span> To
              generate questions from your provided documents and deliver them
              to you.
            </div>
            <div>
              <span className="font-semibold">Communication:</span> To respond
              to your inquiries, send important updates, and provide customer
              support.
            </div>
            <div>
              <span className="font-semibold">Improvement:</span> To analyze
              usage patterns, detect and prevent fraud or misuse, and enhance
              our service.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            3. Data Security
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              We take the security of your personal information seriously. We
              use industry-standard security measures to protect your data from
              unauthorized access, disclosure, alteration, or destruction.
              However, no method of transmission over the internet or electronic
              storage is completely secure, and we cannot guarantee absolute
              security.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            4. User Responsibility
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              It is the user&#39;s responsibility to ensure that any documents
              uploaded to <b>QuizMD</b> do not violate copyright laws or
              infringe upon the intellectual property rights of others. We do
              not actively monitor or review the content of uploaded documents
              for copyright violations.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            5. Third-Party Services
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              <b>QuizMD</b> may use third-party services, such as analytics and
              payment processors. These third parties may have their own privacy
              policies, and we encourage you to review their policies before
              using their services.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">
            6. Changes to this Privacy Policy
          </h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              We reserve the right to update this Privacy Policy to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We will notify you of any material changes by
              posting the updated Privacy Policy on our website.
            </div>
          </div>

          <h2 className="text-xl xl:text-2xl font-bold pt-4">7. Contact Us</h2>
          <div className="flex flex-col py-4 gap-2">
            <div>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at{" "}
              <span className="text-blue-500">privacy@quizmd.ai</span>.
            </div>
            <div>
              By using <b>QuizMD</b>, you acknowledge that you have read and
              agree to this Privacy Policy.
            </div>
          </div>

          <div className="flex flex-col pt-10">
            <span className="font-bold text-xl">QuizMD</span>
            <span className="text-blue-500">
              <Link to="/">https://quizmd.ai</Link>
            </span>
            <span>contact@quizmd.ai</span>
          </div>
        </section>
      </Container>
      <Footer />
    </main>
  );
};

export default PrivacyPage;
