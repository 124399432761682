import {
  CredentialResponse,
  GoogleLogin,
  googleLogout,
} from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import AuthService from "../../services/AuthService";
import { TenantContext } from "../../context/tenant-context";

const LoginButton: React.FC<{ small?: boolean | undefined }> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const navigate = useNavigate();
  const ctx = useContext(TenantContext);

  // login function
  const onLogin = useCallback(
    (credentialResponse: CredentialResponse) => {
      setIsLoading(true);
      AuthService.signInUsingGoogleCredentials(credentialResponse)
        .then((value) => {
          setHasError(false);
          // setting tenant id from signin response
          ctx.setTenantInfo(value.data);
          // navigate to the generator
          navigate("/generator");
          setIsLoading(false);
        })
        .catch(() => {
          setHasError(true);
          setIsLoading(false);
        });
    },
    [setHasError, setIsLoading, navigate, ctx.setTenantInfo],
  );

  // logout function
  const logout = () => {
    setIsLoading(true);
    setHasError(false);
    googleLogout();
    AuthService.signOut()
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {});
    ctx.setTenantInfo(undefined);
    navigate("/");
  };

  const onLoginStarted = () => {
    setIsLoading(true);
    setHasError(false);
  };

  const onLoginFailed = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div className="flex justify-center">
      {hasError && (
        <GoogleLogin
          onSuccess={onLogin}
          onError={onLoginFailed}
          click_listener={onLoginStarted}
          useOneTap
        />
      )}
      {!hasError && isLoading && (
        <button className="flex gap-2 h-10 px-4 py-2 font-semibold sm:font-bold border border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150">
          <span className="text-sm loading loading-spinner"></span>
          <span className="text-sm">Working</span>
        </button>
      )}
      {!isLoading &&
        (ctx.isLoggedIn ? (
          <button
            onClick={logout}
            className="flex gap-2 h-10 px-4 py-2 font-semibold sm:font-bold border border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150"
          >
            <span className="text-sm">Logout</span>
          </button>
        ) : (
          <GoogleLogin
            onSuccess={onLogin}
            onError={onLoginFailed}
            click_listener={onLoginStarted}
            useOneTap
          />
        ))}
    </div>
  );
};

export default LoginButton;
