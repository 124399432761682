import clsx from "clsx";
import { IMCQItem } from "../../../../../types/JobsResponse";
import { useCallback, useContext, useState } from "react";
import { TenantContext } from "../../../../../context/tenant-context";
import Tag from "../../../../Shared/Tag";
import MCQEditControls from "./MCQEditControls";

const MCQItem: React.FC<{
  jobId: string;
  mcqItem: IMCQItem;
  qNum: number;
  index: number;
  showEditControls: boolean;
  isLast?: boolean;
  isSeries?: boolean;
  collectionUpdated: (value: boolean) => void;
}> = (props) => {
  const ctx = useContext(TenantContext);
  const tenantId = ctx.tenantId;

  const [checked, setChecked] = useState(false);

  const toggleExp = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  const renderBloomClassification = () => {
    return (
      <Tag
        prefix="Bloom:"
        tooltip="Bloom Taxonomy Classification:"
        showPrefix={true}
        text={props.mcqItem.bloomClassification!}
        bgColor="#1CB0F6"
        textColor="white"
      />
    );
  };

  return (
    <div
      className={clsx("space-y-4 pb-5 text-sm md:text-base w-full", {
        "border-b mb-4": !props.isLast && !ctx.isSlideShowView,
      })}
    >
      <div className="flex justify-between">
        <div className="flex flex-row font-semibold self-end">
          <div className="mr-2">
            <h6>Question {props.qNum + 1}</h6>
          </div>
          <div className="hidden md:flex flex-row gap-x-2">
            {props.mcqItem.bloomClassification && renderBloomClassification()}
          </div>
        </div>
        {props.showEditControls && (
          <MCQEditControls
            jobId={props.jobId}
            index={props.index}
            onDiscarded={() => {
              props.mcqItem.discarded = true;
              props.collectionUpdated(true);
            }}
          />
        )}
        {props.mcqItem.discarded && <p>discarded</p>}
      </div>
      <div style={{ whiteSpace: "pre-wrap" }} className="font-medium">
        {props.mcqItem.question}
      </div>
      {props.mcqItem.options && (
        <div className="ml-4 space-y-3">
          <div>
            <span className="font-semibold mr-2">A.</span>
            {props.mcqItem.options.A}
          </div>
          <div>
            <span className="font-semibold mr-2">B.</span>
            {props.mcqItem.options.B}
          </div>
          <div>
            <span className="font-semibold mr-2">C.</span>
            {props.mcqItem.options.C}
          </div>
          <div>
            <span className="font-semibold mr-2">D.</span>
            {props.mcqItem.options.D}
          </div>
          <div>
            <span className="font-semibold mr-2">E.</span>
            {props.mcqItem.options.E}
          </div>
        </div>
      )}
      <div className="">
        <label className={ctx.isSolutionVisible ? "" : "swap"}>
          <input
            type="checkbox"
            onChange={toggleExp}
            className={ctx.isSolutionVisible ? "hidden" : ""}
          />
          <div className="swap-on space-y-2 border rounded-xl p-4 bg-white">
            <div className="font-semibold">
              <div className="flex flex-row">
                <div className="mr-2">Answer:</div>
                <div className="">{props.mcqItem.correctAnswer}</div>
              </div>
            </div>
            <div>
              <div className="font-semibold mr-1">Explanation:</div>
              <div>
                {(ctx.isSolutionVisible || checked) &&
                  props.mcqItem.explanation}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              { hidden: ctx.isSolutionVisible ?? false },
              "swap-off border rounded-xl lshaded grid justify-items-center flex items-center",
            )}
          >
            <div className="flex align-self-center text-[#F000B8] text-sm font-semibold px-4">
              CLICK TO REVEAL ANSWER
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default MCQItem;
