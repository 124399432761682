import { useContext, useEffect, useState } from "react";
import Explorer from "../components/Explorer/Explorer";
import SideExplorer from "../components/Explorer/SideExplorer/SideExplorer";
import { ExploreContext } from "../context/explore-context";
import Bars3Icon from "@heroicons/react/24/solid/Bars3Icon";

const ExplorerPage: React.FC<{}> = (props) => {
  const ctx = useContext(ExploreContext);
  const [isChecked, setIsChecked] = useState(true);

  const checkHandler = () => {
    setIsChecked(!isChecked);
    ctx.setOpenDrawer(!isChecked);
  };

  useEffect(() => {
    setIsChecked(ctx.openDrawer);
  }, [ctx.openDrawer]);

  return (
    <main className="ease-soft-in-out h-full w-full rounded-xl transition-all duration-200">
      <div className="fixed bottom-24 right-10 z-[999] block lg:hidden">
        <button
          className="w-10 h-10 rounded-full bg-white border border-[#F000B8] p-2 text-[#F000B8]"
          onClick={checkHandler}
        >
          <Bars3Icon />
        </button>
      </div>
      <div className="l-[40px] mx-auto">
        <div className="drawer lg:drawer-open lg:pr-2 flex w-full">
          <input
            id="my-drawer"
            type="checkbox"
            className="drawer-toggle"
            checked={isChecked}
            onChange={checkHandler}
          />
          <div className="drawer-side h-[calc(100%-65px)] z-990">
            <label
              htmlFor="my-drawer invisible"
              className="drawer-overlay"
            ></label>
            <SideExplorer />
          </div>
          <div className="drawer-content lg:w-[calc(100%-440px)] h-full w-full pt-6">
            <Explorer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExplorerPage;
