import { useContext, useEffect, useRef } from "react";
import MCQItem from "./MCQItem";
import { ExploreContext } from "../../../context/explore-context";
import { IMCQItem } from "../../../types/JobsResponse";
import Loading from "../../Shared/Loading";
import SharedItemHeader from "../SharedItemHeader";

const MCQExplorer: React.FC<{}> = (props) => {
  const ctx = useContext(ExploreContext);
  const mcqData = ctx.exploreData;
  const selectedItem =
    ctx.getSelectedItem() ?? (mcqData?.length ? mcqData[0] : null);

  // used for scroll to bottom
  const itemsEndRef = useRef<HTMLInputElement | null>(null);

  // scroll to bottom when items are added
  useEffect(() => {
    const scrollToTop = () => {
      if (itemsEndRef?.current) {
        itemsEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    // call the function
    scrollToTop();
  }, [ctx.selectedItemId]);

  if (!selectedItem) {
    return (
      <div className="mx-2 sm:mx-6 my-4 p-4">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div ref={itemsEndRef} style={{ scrollMarginTop: "30px" }}></div>
      <div className="flex flex-row justify-start mx-1 md:mx-4 px-1 md:px-4 pt-2 sm:pt-4">
        <div className="w-full">
          <SharedItemHeader item={selectedItem} showTags={false} />
        </div>
      </div>
      {(() => {
        let prevStem: string | undefined = "";
        return selectedItem?.questions.map((question: IMCQItem, i) => {
          return (
            <div key={`exp-${selectedItem.jobId}-${i}`}>
              <div className="mx-2 sm:mx-4 md:mx-10 my-4 px-4 sm:px-4 py-4 border-0 rounded-t-inherit rounded-xl bg-gray-50 sm:block border-b">
                <div className="flex flex-col items-start">
                  <div className="w-full">
                    {"stem" in question && prevStem !== question["stem"] && (
                      <div className="text-sm md:text-base">
                        <div className="font-bold">Clinical case:</div>
                        <div style={{ whiteSpace: "pre-wrap" }}>
                          {question.stem}
                        </div>
                      </div>
                    )}
                    {"stem" in question && prevStem === question["stem"] && (
                      <div className="text-sm md:text-base">
                        <div className="font-bold">
                          Continued from previous case:
                        </div>
                      </div>
                    )}
                    <MCQItem
                      isSeries={"stem" in question}
                      isLast={i === mcqData.length - 1}
                      index={i}
                      mcqItem={question}
                    />
                  </div>
                </div>
              </div>
              {
                // update the prev stem
                (prevStem = "stem" in question ? question["stem"] : "") && <></>
              }
            </div>
          );
        });
      })()}
    </>
  );
};

export default MCQExplorer;
