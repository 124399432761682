const Loading: React.FC<{}> = (props) => {
  return (
    <div className="my-4 px-4 py-20 border-b rounded-t-inherit rounded-xl bg-gray-50 sm:block">
      <div className="flex flex-col items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
        <span>Loading</span>
      </div>
    </div>
  );
};

export default Loading;
