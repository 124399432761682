const Tag: React.FC<{
  prefix?: string;
  showPrefix?: boolean;
  text: string;
  tooltip?: string;
  bgColor: string;
  textColor: string;
}> = (props) => {
  return (
    <div
      className="flex items-center font-normal rounded-2xl my-1 px-2 mx-auto text-xs h-6 tooltip tooltip-bottom"
      data-tip={props.tooltip ? `${props.tooltip} ${props.text}` : props.text}
      style={{ color: props.textColor, backgroundColor: props.bgColor }}
    >
      {props.prefix && props.showPrefix && (
        <span className="hidden md:inline-block mr-1 font-mono">
          {props.prefix}
        </span>
      )}
      <span className="font-bold">{props.text}</span>
    </div>
  );
};

export default Tag;
