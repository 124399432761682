import { useCallback, useContext, useState } from "react";
import { TenantContext } from "../../../../context/tenant-context";
import FileService from "../../../../services/FileService";
import { IJobItem } from "../../../../types/JobsResponse";

const GenerateMore: React.FC<{
  visible: boolean;
  onCompleted: (item: IJobItem) => void;
}> = (props) => {
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [rateLimited, setRateLimited] = useState<boolean>(false);

  const ctx = useContext(TenantContext);
  const tenantId = ctx.tenantId;
  const sessionId = ctx.sessionId;
  const onCompleted = props.onCompleted;

  // function for regenerating from existing file session
  const regenerate = useCallback(() => {
    if (!sessionId) return;

    setIsPending(true);
    setIsFailed(false);
    setRateLimited(false);

    FileService.regenerateByFileSessionId(tenantId, sessionId)
      .then((value) => {
        if (value.data.fileId !== sessionId) {
          return;
        }
        onCompleted(value.data);
        setIsPending(false);
      })
      .catch((error) => {
        setIsPending(false);
        setIsFailed(true);
        // if error code is 429, then its rate limited
        // setRateLimited(true);
      });
  }, [
    tenantId,
    sessionId,
    onCompleted,
    setIsPending,
    setIsFailed,
    setRateLimited,
  ]);

  const renderGenerateMore = () => {
    if (rateLimited) {
      return <span>RATE Limited</span>;
    } else if (isPending) {
      return <span className="loading loading-spinner loading-md"></span>;
    } else if (isFailed) {
      return (
        // generate more button with error in last attempt
        <button
          onClick={regenerate}
          className="px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-2xl shadow-none cursor-pointer leading-pro ease-soft-in text-xs bg-150 active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25 hover:opacity-75"
        >
          <span>Try Generating Again</span>
        </button>
      );
    } else if (props.visible) {
      return (
        // generate more button without any error
        <button
          onClick={regenerate}
          className="px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-2xl shadow-none cursor-pointer leading-pro ease-soft-in text-xs bg-150 active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25 border-[#F000B8] text-[#F000B8] hover:opacity-75"
        >
          <span>Generate More</span>
        </button>
      );
    }
  };

  return (
    <>
      <div className="grid justify-items-center my-10">
        {props.visible && renderGenerateMore()}
      </div>
    </>
  );
};

export default GenerateMore;
