import { useCallback, useContext, useState } from "react";
import { TenantContext } from "../../../../../context/tenant-context";
import FileService from "../../../../../services/FileService";
import clsx from "clsx";

const ExportButton: React.FC<{ fileId: string; format: string }> = (props) => {
  const ctx = useContext(TenantContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const isDocx = props.format === "docx";
  const isXlsx = props.format === "xlsx";

  const exportAndDownload = useCallback(() => {
    setIsLoading(true);
    setHasError(false);
    FileService.getExportedFileUrl(ctx.tenantId, props.fileId, props.format)
      .then((response) => {
        setIsLoading(false);
        setIsSuccess(true);
        const url = response.data;
        const link = document.createElement("a");
        link.download = `export.${props.format}`;
        link.href = url;
        link.click();
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(true);
      });
  }, [ctx, props.fileId, setIsLoading, setHasError, setIsSuccess]);

  if (isSuccess) {
    return <span className="text-green-500">✓ Success</span>;
  }
  if (isLoading) {
    return <span className="loading loading-spinner loading-xs"></span>;
  }
  return (
    <button
      onClick={exportAndDownload}
      className="relative tooltip tooltip-top"
      data-tip={`Export as ${
        props.format === "docx" ? "MS Word" : "MS Excel"
      } file`}
    >
      {hasError ? (
        <span className="text-orange-500">Retry</span>
      ) : (
        <>
          <div
            className={clsx(
              "w-[24px] h-[24px] pl-1 pr-2 py-[2px] text-[#fff] drop-shadow-md italic text-md font-bold",
              { "bg-[#124EB1]": isDocx },
              { "bg-[#107C41]": isXlsx },
            )}
          >
            {isDocx ? "W" : isXlsx ? "X" : "?"}
          </div>
        </>
      )}
    </button>
  );
};

export default ExportButton;
