import clsx from "clsx";
import { useContext } from "react";
import { ISharedJobItem } from "../../../types/FileSessionResponse";
import { ExploreContext } from "../../../context/explore-context";
import SharedItemHeader from "../SharedItemHeader";
import { Mixpanel } from "../../../common/mixpanel-common";

const SideExplorerItem: React.FC<{
  item: ISharedJobItem;
}> = (props) => {
  const ctx = useContext(ExploreContext);
  const isSelected = ctx.selectedItemId === props.item.jobId;

  const itemClicked = () => {
    ctx.setSelectedItemId(props.item.jobId);
    ctx.setOpenDrawer(false);
    Mixpanel.track("SharedItemClicked", props.item);
  };

  return (
    <div
      className={clsx(
        { "bg-blue-500 bg-opacity-5": isSelected },
        "hover:shadow-soft-xs cursor-pointer border-b w-full",
      )}
    >
      <div onClick={itemClicked}>
        <SharedItemHeader item={props.item} showTags={true} />
      </div>
    </div>
  );
};

export default SideExplorerItem;
