import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IFileSession,
  IGenerationLimits,
  IGenerationLimitsResponse,
} from "../types/FileSessionResponse";
import mixpanel from "mixpanel-browser";
import { ITenantInfo } from "../types/AuthResponse";
import { IMCQGenerationSettings } from "../types/MCQGeneration";
import { IJobItem } from "../types/JobsResponse";
import growthbook from "../common/growthbook-common";

interface Props {
  children: React.ReactNode;
}

const defaultMCQGenSettings: IMCQGenerationSettings = {
  clinicalCaseMCQs: false,
  factBasedMedicalMCQs: true,
  factBasedMedicalMCQsPurposeAligned: false,
  clinicalCaseBasedLearning: false,
};

type TenantContextObj = {
  tenantId: string;
  tenantInfo: ITenantInfo | undefined;
  sessionId: string;
  historyItems: IFileSession[];
  isSolutionVisible: boolean;
  isSlideShowView: boolean;
  deletionRequested: boolean;
  needReload: boolean;
  silentReload: boolean;
  openDrawer: boolean;
  deletingSessionId: string;
  deleteInProgress: boolean;
  videoOpen: boolean;
  isLoggedIn: boolean;
  mcqGenSettings: IMCQGenerationSettings;
  currentJobData: IJobItem[];
  limits: IGenerationLimits | null;
  limitExceeded: boolean;
  recheckLimit: boolean;
  setTenantInfo: (value: ITenantInfo | undefined) => void;
  setSelectedSessionId: (id: string) => void;
  getSelectedSession: () => IFileSession | undefined;
  addHistoryItem: (item: IFileSession) => void;
  setupHistoryItems: (items: IFileSession[]) => void;
  setSolutionVisibility: (visible: boolean) => void;
  setSlideShowView: (slideShow: boolean) => void;
  setNeedReload: (set: boolean) => void;
  setSilentReload: (set: boolean) => void;
  onSessionItemDeleted: () => void;
  onSessionItemDeletionClosed: () => void;
  deleteSessionItem: (sessionId: string) => void;
  setDeleteInProgress: (inProgress: boolean) => void;
  setVideoOpen: (value: boolean) => void;
  setOpenDrawer: (set: boolean) => void;
  setMCQGenSettings: (settings: IMCQGenerationSettings) => void;
  setCurrentJobData: (data: IJobItem[]) => void;
  setLimits: (value: IGenerationLimits) => void;
  setRecheckLimit: (set: boolean) => void;
};

export const TenantContext = React.createContext<TenantContextObj>({
  /*dummy for type hinting and code completion*/
  tenantId: "",
  tenantInfo: undefined,
  sessionId: "",
  historyItems: [],
  isSolutionVisible: false,
  isSlideShowView: false,
  deletionRequested: false,
  needReload: false,
  silentReload: false,
  openDrawer: false,
  deletingSessionId: "",
  deleteInProgress: false,
  videoOpen: false,
  isLoggedIn: false,
  mcqGenSettings: defaultMCQGenSettings,
  currentJobData: [],
  limits: null,
  limitExceeded: false,
  recheckLimit: false,
  setTenantInfo: (value: ITenantInfo | undefined) => {},
  setSelectedSessionId: (id: string) => {},
  getSelectedSession: () => undefined,
  addHistoryItem: (item: IFileSession) => {},
  setupHistoryItems: (items: IFileSession[]) => {},
  setSolutionVisibility: (visible: boolean) => {},
  setSlideShowView: (slideShow: boolean) => {},
  setNeedReload: (set: boolean) => {},
  setSilentReload: (set: boolean) => {},
  onSessionItemDeleted: () => {},
  onSessionItemDeletionClosed: () => {},
  deleteSessionItem: (sessionId: string) => {},
  setDeleteInProgress: (inProgress: boolean) => {},
  setVideoOpen: (value: boolean) => {},
  setOpenDrawer: (set: boolean) => {},
  setMCQGenSettings: (settings: IMCQGenerationSettings) => {},
  setCurrentJobData: (data: IJobItem[]) => {},
  setLimits: (value: IGenerationLimits) => {},
  setRecheckLimit: (value: boolean) => {},
});

const TenantContextProvider: React.FC<Props> = ({ children }) => {
  const [tenantInfo, setTenantInfo] = useState<ITenantInfo | undefined>(() => {
    const storedValue = localStorage.getItem("tenantInfo");
    if (!storedValue || storedValue === "undefined") {
      return undefined;
    }
    // parse the object
    const storedObject: ITenantInfo = JSON.parse(storedValue!);
    // identify to mixpanel
    mixpanel.identify(storedObject.tenantId);
    // set tenant id for user attributes
    growthbook.setAttributes({
      tenant_id: storedObject.tenantId,
    });
    return storedObject;
  });

  const [needReload, setNeedReload] = useState<boolean>(true);
  const [silentReload, setSilentReload] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);
  const [sessionId, setSessionId] = useState<string>("");
  const [isDeletionRequest, setDeletionRequested] = useState<boolean>(false);
  const [deletingSessionId, setDeletingSessionId] = useState<string>("");
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [mcqGenSettings, setMCQGenSettings] = useState<IMCQGenerationSettings>(
    defaultMCQGenSettings,
  );
  const [jobData, setJobData] = useState<IJobItem[]>([]);

  const [isSolutionVisible, setSolutionVisibility] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isSolutionVisible");
    return storedValue?.toLowerCase() === "true" || false;
  });

  const [isSlideShowView, setIsSlideShowView] = useState<boolean>(() => {
    const storedValue = localStorage.getItem("isSlideShowView");
    return storedValue?.toLowerCase() === "true" || false;
  });

  const [historyItems, setHistoryItems] = useState<IFileSession[]>([]);
  const [videoOpen, setVideoOpen] = useState<boolean>(false);

  const [recheckLimit, setRecheckLimit] = useState<boolean>(true);
  const [limits, setLimits] = useState<IGenerationLimits | null>(null);
  const limitExceeded = useMemo(() => {
    if (limits === null) {
      return false;
    }
    return limits?.generationCount >= limits?.generationLimit;
  }, [limits, setLimits]);

  const setSelectedSessionId = (id: string) => {
    // no change
    if (sessionId === id) return;

    setDeletionRequested(false);
    setSessionId(id);
  };

  const getSelectedSession = () => {
    if (sessionId && historyItems) {
      return historyItems.find((item) => item?.id === sessionId);
    }
    return undefined;
  };

  const addHistoryItem = (item: IFileSession) => {
    setHistoryItems((prevItems) => {
      return [item, ...prevItems];
    });
  };

  useEffect(() => {
    setNeedReload(false);
  }, [historyItems]);

  const onSessionItemDeleted = useCallback(() => {
    // item was being deleted and now it is not longer being delete
    if (deletingSessionId.length > 0) {
      const deletedItemIndex = historyItems.findIndex(
        (item) => item?.id === deletingSessionId,
      );
      if (deletedItemIndex >= 0) {
        historyItems.splice(deletedItemIndex, 1);
      }
      setDeletingSessionId("");
      setSessionId("");
    }
  }, [historyItems, deletingSessionId, setDeletingSessionId, setSessionId]);

  const onSessionItemDeletionClosed = useCallback(() => {
    setDeletionRequested(false);
  }, [setDeletionRequested]);

  const deleteSessionItem = useCallback(
    (sessionId: string) => {
      setDeletingSessionId(sessionId);
      setDeletionRequested(true);
    },
    [setDeletingSessionId, setDeletionRequested],
  );

  const onSetTenantInfo = useCallback(
    (tenantInfo: ITenantInfo) => {
      setTenantInfo(tenantInfo);
      // set tenant id for user attributes
      growthbook.setAttributes({
        tenant_id: tenantInfo.tenantId,
      });
    },
    [setTenantInfo],
  );

  const contextValue: TenantContextObj = {
    tenantId: tenantInfo?.tenantId ?? "",
    tenantInfo: tenantInfo,
    sessionId: sessionId,
    historyItems: historyItems,
    isSolutionVisible: isSolutionVisible,
    isSlideShowView: isSlideShowView,
    deletionRequested: isDeletionRequest,
    needReload: needReload,
    silentReload: silentReload,
    openDrawer: openDrawer,
    deletingSessionId: deletingSessionId,
    deleteInProgress: deleteInProgress,
    videoOpen: videoOpen,
    isLoggedIn: (tenantInfo?.tenantId?.length ?? 0) > 0,
    mcqGenSettings: mcqGenSettings,
    currentJobData: jobData,
    limits: limits,
    limitExceeded: limitExceeded,
    recheckLimit: recheckLimit,
    setTenantInfo: setTenantInfo,
    setSelectedSessionId: setSelectedSessionId,
    getSelectedSession: getSelectedSession,
    addHistoryItem: addHistoryItem,
    setupHistoryItems: setHistoryItems,
    setSolutionVisibility: setSolutionVisibility,
    setSlideShowView: setIsSlideShowView,
    setNeedReload: setNeedReload,
    setSilentReload: setSilentReload,
    setOpenDrawer: setOpenDrawer,
    onSessionItemDeleted: onSessionItemDeleted,
    onSessionItemDeletionClosed: onSessionItemDeletionClosed,
    deleteSessionItem: deleteSessionItem,
    setDeleteInProgress: setDeleteInProgress,
    setVideoOpen: setVideoOpen,
    setMCQGenSettings: setMCQGenSettings,
    setCurrentJobData: setJobData,
    setLimits: setLimits,
    setRecheckLimit: setRecheckLimit,
  };

  useEffect(() => {
    localStorage.setItem("tenantInfo", JSON.stringify(tenantInfo));
  }, [tenantInfo]);

  useEffect(() => {
    localStorage.setItem("isSolutionVisible", String(isSolutionVisible));
  }, [isSolutionVisible]);

  useEffect(() => {
    localStorage.setItem("isSlideShowView", String(isSlideShowView));
  }, [isSlideShowView]);

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;
