import { useContext } from "react";
import { TenantContext } from "../../../context/tenant-context";
import FileSession from "./FileSession/FileSession";
import Generation from "./Generation/Generation";

const Session: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const sessionId = ctx.sessionId;

  return (
    <>
      <FileSession />
      {!ctx.deleteInProgress && sessionId && (
        <Generation fileSessionId={sessionId} />
      )}
    </>
  );
};

export default Session;
