import { useContext, useEffect, useState } from "react";
import LoginButton from "../components/Shared/LoginButton";
import { TenantContext } from "../context/tenant-context";
import { Link } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import AuthService from "../services/AuthService";

const LoginPage: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    if (ctx.isLoggedIn) {
      // force logout
      googleLogout();
      AuthService.signOut()
        .then(() => {})
        .catch(() => {});
      ctx.setTenantInfo(undefined);
      setLoggedOut(true);
    }
  }, [setLoggedOut, ctx, ctx.isLoggedIn, ctx.setTenantInfo]);

  return (
    <main className="mt-0 transition-all duration-200 ease-soft-in-out ps bg-gray-100">
      <Link to={"/"} className="flex flex-row items-center pt-4 pl-4 gap-2">
        <svg
          className="h-8 w-8 text-slate-500"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 8 8 12 12 16" />
          <line x1="16" y1="12" x2="8" y2="12" />
        </svg>
        <div>Home</div>
      </Link>
      <section className="min-w-[370px] flex flex-col justify-start items-center h-screen opacity-80 py-[100px] lg:py-[200px]">
        <div className="py-2 lg:py-4">
          <img
            src="./assets/img/logo.svg"
            className="ml-2 h-20 w-20 lg:h-[120px] lg:w-[120px]"
            alt="quizmd logo"
          />
        </div>
        <div>
          <h1 className="font-bold text-lg lg:text-2xl">QuizMD</h1>
        </div>
        <div>
          <span className="text-sm">Generate MCQs from your documents</span>
        </div>
        <div className="grid mt-4 lg:mt-10 max-w-md w-full bg-white rounded p-6 space-y-4 ">
          <div className="justify-self-center">
            <div className="mb-4">
              {loggedOut ? (
                <h2 className="text-md font-semibold text-rose-500">
                  Your session has expired! Please login to continue
                </h2>
              ) : (
                <h2 className="text-md">
                  Please use your Google account to get started
                </h2>
              )}
            </div>
            <div className="py-4">
              <LoginButton />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default LoginPage;
