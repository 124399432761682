import { useCallback, useContext } from "react";
import { TenantContext } from "../../../context/tenant-context";
import { IfFeatureEnabled } from "@growthbook/growthbook-react";

const MCQGenSettings: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const mcqGenSettings = ctx.mcqGenSettings;
  const setMCQGenSettings = ctx.setMCQGenSettings;

  const selectClinicalCaseMCQs = useCallback(() => {
    const newSettings = { ...mcqGenSettings };
    newSettings.factBasedMedicalMCQs = false;
    newSettings.factBasedMedicalMCQsPurposeAligned = false;
    newSettings.clinicalCaseMCQs = true;
    newSettings.clinicalCaseBasedLearning = false;
    setMCQGenSettings(newSettings);
  }, [mcqGenSettings, setMCQGenSettings]);

  const selectFactBasedMedicalMCQs = useCallback(() => {
    const newSettings = { ...mcqGenSettings };
    newSettings.factBasedMedicalMCQs = true;
    newSettings.factBasedMedicalMCQsPurposeAligned = false;
    newSettings.clinicalCaseMCQs = false;
    newSettings.clinicalCaseBasedLearning = false;
    setMCQGenSettings(newSettings);
  }, [mcqGenSettings, setMCQGenSettings]);

  const selectFactBasedMedicalMCQsPurposeAligned = useCallback(() => {
    const newSettings = { ...mcqGenSettings };
    newSettings.factBasedMedicalMCQs = false;
    newSettings.factBasedMedicalMCQsPurposeAligned = true;
    newSettings.clinicalCaseMCQs = false;
    newSettings.clinicalCaseBasedLearning = false;
    setMCQGenSettings(newSettings);
  }, [mcqGenSettings, setMCQGenSettings]);

  const selectClinicalCaseBasedLearning = useCallback(() => {
    const newSettings = { ...mcqGenSettings };
    newSettings.factBasedMedicalMCQs = false;
    newSettings.factBasedMedicalMCQsPurposeAligned = false;
    newSettings.clinicalCaseMCQs = false;
    newSettings.clinicalCaseBasedLearning = true;
    setMCQGenSettings(newSettings);
  }, [mcqGenSettings, setMCQGenSettings]);

  return (
    <>
      <div className="w-auto flex flex-col sm:flex-row gap-x-4 rounded-t-inherit rounded-xl bg-gray-50 p-2">
        <div className="flex items-center gap-x-2 flex-row justify-between rounded-2xl w-auto px-4 sm:px-2">
          <div className="flex flex-row">
            <div className="form-control">
              <label className="cursor-pointer label gap-x-2">
                <span className="text-sm font-semibold">
                  Fact-based (Simple):
                </span>
                <input
                  type="radio"
                  className="radio radio-secondary"
                  checked={mcqGenSettings.factBasedMedicalMCQs}
                  onChange={selectFactBasedMedicalMCQs}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="border-r"></div>
        <div className="flex items-center gap-x-2 flex-row justify-between rounded-2xl w-auto px-4 sm:px-2">
          <div className="flex flex-row">
            <div className="form-control">
              <label className="cursor-pointer label gap-x-2">
                <span className="text-sm font-semibold">
                  Fact-based (Purpose Aligned):
                </span>
                <input
                  type="radio"
                  className="radio radio-secondary"
                  checked={mcqGenSettings.factBasedMedicalMCQsPurposeAligned}
                  onChange={selectFactBasedMedicalMCQsPurposeAligned}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="border-r"></div>
        <div className="flex items-center gap-x-2 flex-row justify-between rounded-2xl w-auto px-4 sm:px-2">
          <div className="flex flex-row">
            <div className="form-control">
              <label className="cursor-pointer label gap-x-2">
                <span className="text-sm font-semibold">Clinical MCQs:</span>
                <input
                  type="radio"
                  className="radio radio-secondary"
                  checked={mcqGenSettings.clinicalCaseMCQs}
                  onChange={selectClinicalCaseMCQs}
                />
              </label>
            </div>
          </div>
        </div>
        <IfFeatureEnabled feature="experimental-settings">
          <div className="border-r"></div>
          <div className="flex items-center gap-x-2 flex-row justify-between rounded-2xl w-auto px-4 sm:px-2">
            <div className="flex flex-row">
              <div className="form-control">
                <label className="cursor-pointer label gap-x-2">
                  <span className="text-sm font-semibold">
                    Case based learning:
                  </span>
                  <input
                    type="radio"
                    className="radio radio-secondary"
                    checked={mcqGenSettings.clinicalCaseBasedLearning}
                    onChange={selectClinicalCaseBasedLearning}
                  />
                </label>
              </div>
            </div>
          </div>
        </IfFeatureEnabled>
      </div>
    </>
  );
};

export default MCQGenSettings;
