import { useCallback, useContext, useState, ChangeEvent } from "react";
import { TenantContext } from "../../../context/tenant-context";
import FileService from "../../../services/FileService";
import { Mixpanel } from "../../../common/mixpanel-common";

const KeywordInput: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const tenantId = ctx.tenantId;
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [textInput, setTextInput] = useState<string>("");

  const EXAMPLE_TEXT =
    "Parkinson’s disease (PD) is a very common brain abnormality that affects people all over the world. Early detection of such abnormality is critical in clinical diagnosis in order to prevent disease progression. Electroencephalography (EEG) is one of the most important PD diagnostic tools since this disease is linked to the brain. In this study, novel efficient common spatial pattern-based approaches for detecting Parkinson’s disease in two cases, off–medication and on–medication, are proposed. First, the EEG signals are preprocessed to remove major artifacts before spatial filtering using a common spatial pattern. Several features are extracted from spatially filtered signals using different metrics, namely, variance, band power, energy, and several types of entropy. Machine learning techniques, namely, random forest, linear/quadratic discriminant analysis, support vector machine, and k-nearest neighbor, are investigated to classify the extracted features. The impacts of frequency bands, segment length, and reduction number on the results are also investigated in this work. The proposed methods are tested using two EEG datasets: the SanDiego dataset (31 participants, 93 min) and the UNM dataset (54 participants, 54 min). The results show that the proposed methods, particularly the combination of common spatial patterns and log energy entropy, provide competitive results when compared to methods in the literature. The achieved results in terms of classification accuracy, sensitivity, and specificity in the case of off-medication PD detection are around 99%. In the case of on-medication PD, the results range from 95% to 98%. The results also reveal that features extracted from the alpha and beta bands have the highest classification accuracy.";

  const generateNow = useCallback(() => {
    setHasError(false);
    setSubmitting(true);

    FileService.generateFromPlainText(tenantId, textInput, ctx.mcqGenSettings)
      .then((response) => {
        // add this as a session in the history
        ctx.addHistoryItem(response.data);

        // also set it as the active session
        ctx.setSelectedSessionId(response.data.id);

        // finished submitting
        setSubmitting(false);

        // tracking
        Mixpanel.track("PlainTextSubmitted", {
          FileId: response.data.id,
        });
      })
      .catch((err) => {
        setHasError(true);
        setSubmitting(false);
      });
  }, [tenantId, textInput, setHasError, ctx]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTextInput(event.target.value);
    },
    [setTextInput],
  );

  return (
    <>
      {/*<!-- Plain tags  -->*/}
      <div className="flex p-2 sm:p-6 border-b rounded-t-inherit rounded-xl bg-gray-50">
        <div className="lg:block p-4 md:p-10 flex flex-col h-full w-full border-2 rounded-lg border-dashed rounded-t-inherit rounded-xl bg-gray-50">
          <p className="mb-2 text-gray-500 py-2 overflow-hidden">
            <span className="text-md md:text-lg font-semibold">
              Provide text as input
            </span>
            <span className="hidden md:inline mr-1">, or use </span>
            <span
              className="hidden md:inline whitespace-nowrap border rounded-lg p-2 text-sm bg-gray-200 text-black mx-1 cursor-pointer"
              onClick={() => setTextInput(EXAMPLE_TEXT)}
            >
              Example Text
            </span>
          </p>
          {submitting ? (
            <div className="flex border rounded-lg h-10 w-full justify-center items-center">
              <div className="loading loading-spinner loading-sm"></div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row text-sm min-h-10 w-full">
              <div className="grid justify-items-stretch w-full">
                <textarea
                  value={textInput}
                  onChange={handleChange}
                  name="textInput"
                  placeholder="Type or paste text input here"
                  className="border rounded-md border-slate-300 px-2"
                  rows={5}
                  maxLength={20000}
                />
              </div>
              <button
                className="m-0 md:ml-2 bg-gray-200 flex gap-2 h-10 px-4 py-2 font-semibold sm:font-bold border border-slate-300 rounded-lg text-slate-400 enabled:text-slate-700 enabled:hover:border-slate-400 enabled:hover:text-slate-900 enabled:hover:shadow transition duration-150"
                onClick={generateNow}
                disabled={!textInput || textInput.length <= 0}
                data-tooltip-id="m-tooltip"
                data-tooltip-content="Input plain text"
              >
                <div className="text-center w-full">Generate</div>
              </button>
            </div>
          )}
          {hasError && (
            <div className="text-sm text-rose-500">
              An error occurred while trying to send your request, please try
              again.
            </div>
          )}
        </div>
      </div>
      {/*<!-- end Upload  -->*/}
    </>
  );
};

export default KeywordInput;
