import React from "react";
import Container from "./container";

const Testimonials = () => {
  return (
    <Container>
      <div className="grid gap-10 lg:grid-cols-2 xl:grid-cols-3">
        <div className="lg:col-span-2 xl:col-auto">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-14 rounded-2xl py-14 dark:bg-trueGray-800">
            <p className="text-xl leading-normal ">
              Its just <Mark>wonderful</Mark>... Thanks, its so much of an{" "}
              <Mark>enigma</Mark> for me to see a program do something that
              would take me at <Mark>least an hour</Mark>.
            </p>
            <Author name="Anonymous" title="Pakistan" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-14 rounded-2xl py-14 dark:bg-trueGray-800">
            <p className="text-xl leading-normal ">
              Its <Mark>amazing</Mark> resource. I used it. It's like a{" "}
              <Mark>miracle</Mark>. Indeed an digital innovation in medical
              education. Let us see if it can help a non tech savvy person like
              me.
            </p>
            <Author name="Imjad Hussain" title="Pakistan" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-14 rounded-2xl py-14 dark:bg-trueGray-800">
            <p className="text-xl leading-normal ">
              This is a <Mark>great innovation</Mark> carefully designed to make
              learning simple and focused. There is no doubt that it will be a{" "}
              <Mark>game changer</Mark> in medical education both for now and in
              the future.
            </p>
            <Author name="Ernest Nwazor" title="Nigeria" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-14 rounded-2xl py-14 dark:bg-trueGray-800">
            <p className="text-xl leading-normal ">
              <Mark>Wonderful invention!</Mark> Extremely good quality questions
              and good user experience. Looking forward to seeing it go places!
            </p>
            <Author name="Zoha Aziz" title="Pakistan" />
          </div>
        </div>
        <div className="">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-14 rounded-2xl py-14 dark:bg-trueGray-800">
            <p className="text-xl leading-normal ">
              <Mark>Good Initiative!</Mark> It will definitely help students
              practice more and more questions based on their mistakes and
              highlighted notes. <Mark>Note-worthy indeed!</Mark>
            </p>
            <Author name="Mansavi Gupta" title="India" />
          </div>
        </div>
      </div>
    </Container>
  );
};

function Author(props) {
  return (
    <div className="flex items-center mt-8 space-x-3">
      <div>
        <div className="text-lg font-semibold">{props.name}</div>
        <div className="text-gray-600 dark:text-gray-400">{props.title}</div>
      </div>
    </div>
  );
}

function Mark(props) {
  return (
    <>
      {" "}
      <mark className="text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
        {props.children}
      </mark>{" "}
    </>
  );
}

export default Testimonials;
