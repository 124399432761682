import { useCallback, useState } from "react";
import FileService from "../../../../services/FileService";
import ShareDialog from "./ShareDialog";
import clsx from "clsx";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { Mixpanel } from "../../../../common/mixpanel-common";

const FileShareButton: React.FC<{
  tenantId: string;
  sessionId: string;
  sessionShared?: boolean;
}> = (props) => {
  const [shared, setShared] = useState<boolean>(props.sessionShared ?? false);
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [sharingError, setSharingError] = useState<boolean>(false);

  const shareFile = useCallback(
    (share: boolean) => {
      // track the event in mixpanel
      const eventName = share ? "SharedFileSession" : "UnsharedFileSession";
      Mixpanel.track(eventName, {
        tenantId: props.tenantId,
        sessionId: props.sessionId,
      });

      setLoading(true);
      FileService.shareFileSession(props.tenantId, props.sessionId, share)
        .then(() => {
          setOpened(share);
          setShared(share);
          setLoading(false);
        })
        .catch(() => {
          setSharingError(true);
          setLoading(false);
        });
    },
    [setOpened, setShared, props.tenantId, props.sessionId],
  );

  const closedSharedDialog = () => {
    setOpened(false);
  };

  return (
    <>
      <ShareDialog
        key={`sd-${props.sessionId}`}
        open={opened}
        onClose={closedSharedDialog}
        shareUrl={`https://quizmd.ai/explorer?id=${props.sessionId}`}
      />
      <button
        className={clsx(
          "badge font-bold text-center uppercase text-xs",
          { loading: loading },
          { "badge-secondary text-white": shared },
          { "border-[#F000B8] text-[#F000B8]": !shared },
        )}
        onClick={() => shareFile(!shared)}
      >
        {sharingError ? "Retry" : shared ? "Shared" : "Share"}
      </button>
      {sharingError && (
        <div className="float-right py-1 h-4 w-4 text-[#F000B8]">
          <ExclamationTriangleIcon />
        </div>
      )}
    </>
  );
};

export default FileShareButton;
