import InfoIcon from "../../../../Shared/Icons/InfoIcon";
const Discarded: React.FC<{}> = (props) => {
  return (
    <div className="md:mx-6 my-4 px-4">
      <div className="alert alert-info mx-">
        <InfoIcon />
        <span>All MCQs were discarded</span>
      </div>
    </div>
  );
};

export default Discarded;
