import clsx from "clsx";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import LoginButton from "../Shared/LoginButton";
import { MagnifyingGlassIcon, SparklesIcon } from "@heroicons/react/24/solid";
import { useCallback, useContext } from "react";
import { ExploreContext } from "../../context/explore-context";
import { TenantContext } from "../../context/tenant-context";
import { Mixpanel } from "../../common/mixpanel-common";
import CreditCounter from "../Shared/CreditCounter";

const Layout: React.FC<{}> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isGenerator = location.pathname.toLowerCase() === "/generator";
  const eCtx = useContext(ExploreContext);
  const tCtx = useContext(TenantContext);

  const switchToExplorer = useCallback(
    (isExplorer: boolean) => {
      navigate("/explorer");
      eCtx.setOpenDrawer(true);

      if (!isExplorer) Mixpanel.track("SwitchedToExplorer", {});
    },
    [eCtx.setOpenDrawer],
  );

  const switchToGenerator = useCallback(
    (isGenerator: boolean) => {
      navigate("/generator");
      tCtx.setOpenDrawer(false);
      tCtx.setSelectedSessionId("");

      if (!isGenerator) Mixpanel.track("SwitchedToGenerator", {});
    },
    [tCtx.setSelectedSessionId, tCtx.setSelectedSessionId],
  );

  return (
    <>
      <div className="mw2100 xl:px-0 mt-0 transition-all duration-200 ease-soft-in-out ps">
        <div className="grid justify-items-center md:pb-4 px-2 pt-4 grid-cols-3">
          <div className="justify-self-start min-w-[220px]">
            <Link to="/" className="h-full flex flex-row items-center gap-1">
              <img
                src="./assets/img/logo.svg"
                className="h-12 w-12"
                alt="quizmd logo"
              />
              <div className="flex flex-col">
                <span className="text-sm sm:text-lg font-bold text-slate-900">
                  QuizMD
                </span>
                <span className="text-xs sm:text-sm text-slate-900">
                  AI Copilot for MCQs
                </span>
              </div>
            </Link>
          </div>
          <div className="flex flex-row gap-x-4 lg:gap-x-8 invisible lg:visible">
            <div>
              <button
                className={clsx("btn text-md px-6 lg:px-12", {
                  "hover:bg-[#1CB0F6] hover:opacity-100 bg-[#1CB0F6] text-white":
                    isGenerator,
                })}
                onClick={() => switchToGenerator(isGenerator)}
              >
                Generator
              </button>
            </div>
            <div>
              <button
                className={clsx("btn text-md px-6 lg:px-12", {
                  "hover:bg-[#1CB0F6] hover:opacity-100 bg-[#1CB0F6] text-white":
                    !isGenerator,
                })}
                onClick={() => switchToExplorer(!isGenerator)}
              >
                Explorer
              </button>
            </div>
          </div>
          <div className="justify-self-end flex align-middle">
            {tCtx.limits && <CreditCounter limits={tCtx.limits} />}
            <LoginButton />
          </div>
        </div>
        <main>
          <Outlet />
        </main>
      </div>
      <div className="visible lg:invisible fixed z-[999] w-full h-[60px] bg-white shadow-xl bottom-0">
        <div className="grid h-full grid-cols-2 mx-auto">
          <button
            data-tooltip-target="tooltip-generator"
            type="button"
            className={clsx(
              { "border-t-4 border-[#F000B8]": isGenerator },
              { "border-r border-t border-gray-200": !isGenerator },
              "inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group",
            )}
            onClick={() => switchToGenerator(isGenerator)}
          >
            <div
              className={clsx(
                "h-5 w-5",
                { "text-[#F000B8]": isGenerator },
                { "text-gray-20": !isGenerator },
              )}
            >
              <SparklesIcon />
            </div>
            <span className="font-semibold">Generator</span>
          </button>
          <div
            id="tooltip-generator"
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
          >
            Generator
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
          <button
            data-tooltip-target="tooltip-explorer"
            type="button"
            className={clsx(
              { "border-t-4 border-[#F000B8]": !isGenerator },
              { "border-l border-t border-gray-200": isGenerator },
              "inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 group",
            )}
            onClick={() => switchToExplorer(!isGenerator)}
          >
            <div
              className={clsx(
                "h-5 w-5",
                { "text-[#F000B8]": !isGenerator },
                { "text-gray-20": isGenerator },
              )}
            >
              <MagnifyingGlassIcon />
            </div>
            <span className="font-semibold">Explorer</span>
          </button>
          <div
            id="tooltip-explorer"
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip"
          >
            Explorer
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
