import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TenantContext from "./context/tenant-context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ExploreContext from "./context/explore-context";

// no default client id if missing
const google_client_id: string =
  process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={google_client_id}>
      <TenantContext>
        <ExploreContext>
          <App />
        </ExploreContext>
      </TenantContext>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
