import { useEffect, useContext, useState, useCallback } from "react";
import HistoryItem from "./HistoryItem";
import { TenantContext } from "../../../context/tenant-context";
import FileService from "../../../services/FileService";
import Loading from "../../Shared/Loading";
import ErrorIcon from "../../Shared/Icons/ErrorIcon";

const History: React.FC<{}> = (props) => {
  const ctx = useContext(TenantContext);
  const needReload = ctx.needReload;
  const setNeedReload = ctx.setNeedReload;
  const silentReload = ctx.silentReload;
  const setSilentReload = ctx.setSilentReload;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  // function for loading the history
  const loadHistory = useCallback(() => {
    if (!silentReload) {
      setIsLoading(true);
      ctx.setupHistoryItems([]);
    }
    setSilentReload(false);
    setNeedReload(false);

    FileService.getFileSessions(ctx.tenantId)
      .then((value) => {
        ctx.setupHistoryItems(value.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setLoadingError(true);
      });
  }, [ctx, setIsLoading, setNeedReload, silentReload, setSilentReload]);

  // first loading
  useEffect(() => {
    ctx.setNeedReload(true);
  }, [ctx.setNeedReload]);

  /* load history on component load */
  useEffect(() => {
    // call the function
    if (needReload && !loadingError) {
      loadHistory();
    }
  }, [loadingError, needReload, loadHistory]);

  const startNew = () => {
    ctx.setSelectedSessionId("");
    ctx.setOpenDrawer(false);
  };

  const retryLoading = () => {
    setLoadingError(false);
    setNeedReload(true);
  };

  // there is an error
  const renderLoadingError = () => {
    return (
      <div className="my-4">
        <div className="alert flex justify-between">
          <div className="flex flex-row">
            <ErrorIcon />
            <span className="ml-1">Server error</span>
          </div>
          <button
            type="button"
            onClick={retryLoading}
            className="btn shadow-soft-md hover:scale-102 hover:shadow-soft-xs active:opacity-85"
          >
            Retry
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* sidenav  */}
      <aside className="pb-16 lg:pb-0 w-full md:w-[440px] lg:px-2 lg:mr-4 lg:pb-10 relative h-full inset-y-0 block -translate-x-full flex-wrap items-center justify-between overflow-y-auto border-0 bg-white lg:bg-gray-50 p-0 antialiased transition-transform duration-200 left-0 ">
        <div className="inline-flex lg:hidden pl-4 pt-5 md:pl-8 md:pt-5">
          <img
            src="./assets/img/logo.svg"
            className="h-12 w-12"
            alt="quizmd logo"
          />
          <div className="flex flex-col mt-1 ml-1">
            <span className="text-md sm:text-lg font-bold text-slate-900">
              QuizMD
            </span>
            <span className="text-xs sm:text-sm text-slate-900">
              AI Copilot for MCQs
            </span>
          </div>
        </div>
        {/*<!-- table 1 -->*/}
        <div className="flex flex-wrap w-full rounded-2xl bg-white shadow-soft-md">
          <div className="flex-none w-full max-w-full px-3">
            <div className="h-full flex flex-col min-w-0 mb-6 break-words">
              <div className="sticky top-0 text-xl py-6 px-2 pb-0 mb-0 bg-white border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                <div className="flex flex-row justify-between">
                  <h2>History</h2>
                  <button
                    onClick={startNew}
                    className="px-8 py-2 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border border-solid rounded-2xl shadow-none cursor-pointer leading-pro ease-soft-in text-xs bg-150 active:opacity-85 hover:scale-102 tracking-tight-soft bg-x-25 border-[#F000B8] text-[#F000B8] hover:opacity-75"
                  >
                    Start New
                  </button>
                </div>
                <div className="shadow-soft-xs w-full px-0 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-slate-400 opacity-70">
                  <div className="flex flex-row justify-between">
                    <div>Files uploaded in last 30 days:</div>
                    <div
                      className="link text-[#F000B8] ml-1"
                      onClick={() => setNeedReload(true)}
                    >
                      Reload
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-auto px-0 pt-0 pb-2 mx-0 md:mx-2">
                {loadingError ? (
                  renderLoadingError()
                ) : (
                  <div className="grid justify-items-start p-0 overflow-x-hidden ">
                    {
                      // no history to show
                      !isLoading && (ctx.historyItems?.length ?? 0) <= 0 && (
                        <div className="mt-10 flex justify-center text-slate-300 text-sm font-semibold">
                          Nothing to show in history
                        </div>
                      )
                    }
                    <table className="justify-self-stretch">
                      <tbody>
                        {ctx.historyItems?.map((historyItem, i) => {
                          return (
                            <HistoryItem
                              key={`h-${historyItem.id}`}
                              id={historyItem.id}
                              fileName={historyItem.fileName}
                              timestamp={historyItem.timestamp}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                {isLoading && <Loading />}
              </div>
            </div>
          </div>
        </div>
      </aside>

      {/* end sidenav */}
    </>
  );
};

export default History;
