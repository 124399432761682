import moment from "moment";
import { useContext, useState, useEffect } from "react";
import { TenantContext } from "../../../../../context/tenant-context";
import { IJobItem } from "../../../../../types/JobsResponse";
import { JobStatus } from "../../../../../types/JobStatus";
import FileService from "../../../../../services/FileService";

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

const InProgress: React.FC<{
  jobItem: IJobItem;
  timestamp: number;
  onProgessEnded: (jobItem: IJobItem) => void;
}> = (props) => {
  const ctx = useContext(TenantContext);
  const [jobItem, setJobItem] = useState<IJobItem>(props.jobItem);
  const [error, setError] = useState<boolean>(false);

  const dateTime = moment(props.jobItem.timestamp * 1000);
  const minutesElapsed = moment().diff(dateTime, "minutes");
  const tenantId = ctx.tenantId;

  // progress loader
  useEffect(() => {
    // there is an error do nothing
    if (error) {
      return;
    }

    // Loop while the generation is still in progress
    if (
      jobItem.status === JobStatus.JOB_STATUS_PENDING ||
      jobItem.status === JobStatus.JOB_STATUS_INPROGRESS
    ) {
      const fetchData = async () => {
        // idle sleep
        await delay(5000);

        // refetch the job status
        await FileService.getJobStatusById(tenantId, jobItem.id)
          .then(async (value) => {
            // job not complete yet, keep polling
            if (
              value.data === JobStatus.JOB_STATUS_PENDING ||
              value.data === JobStatus.JOB_STATUS_INPROGRESS
            ) {
              // update the data with status info
              // this will cause the useEffect to trigger again for next polling
              setJobItem({
                id: jobItem.id,
                fileId: jobItem.fileId,
                status: value.data,
                statusMessage: jobItem.statusMessage,
                timestamp: jobItem.timestamp,
                questions: [],
              });
              return;
            }

            // job finished, let's fetch the result
            await FileService.getJobById(tenantId, jobItem.id)
              .then((value) => {
                // discovered that the limit has exceeded while waiting for progress
                if (value.data.status === JobStatus.JOB_STATUS_LIMIT_EXCEEDED) {
                  ctx.setRecheckLimit(true);
                }
                setJobItem(value.data);
                props.onProgessEnded(value.data);
              })
              .catch((error) => {
                setError(true);
              });
          })
          .catch((error) => {
            setError(true);
          });
      };

      // call the function async
      fetchData().catch(console.error);
    }
  }, [tenantId, jobItem, props, error]);

  if (error) {
    return (
      <div className="md:mx-6 my-4 px-4">
        <div className="alert mx-">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-12 w-12 sm:h-6 sm:w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>Could not load information from the server</span>
          <button
            type="button"
            onClick={() => {
              setError(false);
            }}
            className="px-6 py-3 mb-0 font-bold text-center uppercase align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer shadow-soft-md bg-x-25 bg-150 leading-pro text-xs ease-soft-in tracking-tight-soft bg-gradient-to-tl from-white-600 to-gray-400 hover:scale-102 hover:shadow-soft-xs active:opacity-85"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-4 md:mx-10 my-4 px-4 py-20 border-b rounded-t-inherit rounded-xl bg-gray-50 sm:block">
      <div className="flex flex-col items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
        <span>Generating MCQs</span>
        <span className="text-xs font-semibold text-slate-400">
          Started at: {dateTime.format("h:mm A")}
        </span>
        {minutesElapsed > 4 /*more then 2 minutes elapsed*/ && (
          <span className="text-xs font-semibold text-slate-500 mt-10">
            Looks like this job is taking longer than expected
          </span>
        )}
      </div>
    </div>
  );
};

export default InProgress;
