import "./App.css";
import packageInfo from "../package.json";
import growthbook from "./common/growthbook-common";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { GrowthBookProvider } from "@growthbook/growthbook-react";
import { useEffect } from "react";

import GeneratorPage from "./pages/GeneratorPage";
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";
import Protected from "./pages/Protected";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import AboutPage from "./pages/AboutPage";
import ExplorerPage from "./pages/ExplorerPage";
import Layout from "./components/Layout/Layout";
import CacheBuster from "react-cache-buster";
import Loading from "./components/Shared/Loading";

const router = createBrowserRouter([
  { path: "/", element: <LandingPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/privacy", element: <PrivacyPage /> },
  { path: "/terms", element: <TermsPage /> },
  { path: "/about", element: <AboutPage /> },
  {
    element: <Layout />,
    children: [
      { path: "/explorer", element: <ExplorerPage /> },
      {
        path: "/generator",
        element: (
          <Protected>
            <GeneratorPage />
          </Protected>
        ),
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true}
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <GrowthBookProvider growthbook={growthbook}>
        <RouterProvider router={router} />
      </GrowthBookProvider>
    </CacheBuster>
  );
}

export default App;
