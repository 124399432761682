import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
const LimitExceededBanner: React.FC<{}> = (props) => {
  return (
    <div className="mx-2">
      <div className="alert bg-warning">
        <span>
          <ExclamationTriangleIcon height={24} width={24} />
        </span>
        <span>Limit exceeded for Free usage</span>
        <span className="text-sm">Please contact:</span>
        <span className="text-sm font-bold">billing@quizmd.ai</span>
      </div>
    </div>
  );
};

export default LimitExceededBanner;
