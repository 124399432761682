import { CredentialResponse } from "@react-oauth/google";
import http from "../common/http-common";
import {
  IGoogleCodeResponse,
  IRefreshTokenResponse,
  ISignInResponse,
  ISignUpResponse,
} from "../types/AuthResponse";

const signUp = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
): Promise<ISignUpResponse> => {
  return http.post("auth/signup", {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
  });
};

const signIn = (email: string, password: string): Promise<ISignInResponse> => {
  return http.post("auth/signin", {
    email: email,
    password: password,
  });
};

const signInUsingGoogle = (
  googleCodeResponse: IGoogleCodeResponse,
): Promise<ISignInResponse> => {
  return http.post("auth/signin/google", googleCodeResponse);
};

const signInUsingGoogleCredentials = (
  googleCodeResponse: CredentialResponse,
): Promise<ISignInResponse> => {
  return http.post("auth/signin/google-one-tap", googleCodeResponse);
};

const signOut = (): Promise<ISignInResponse> => {
  return http.post("auth/signout");
};

const tokenReferesh = (
  refreshToken: string,
): Promise<IRefreshTokenResponse> => {
  return http.post("auth/refresh");
};

const AuthService = {
  signUp,
  signIn,
  signInUsingGoogle,
  signInUsingGoogleCredentials,
  signOut,
  tokenReferesh,
};

export default AuthService;
