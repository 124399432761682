import { GrowthBook } from "@growthbook/growthbook-react";

const growthbookClientKey = process.env.REACT_APP_GROWTHBOOK_CLIENTKEY;
const isDevelopment = process.env.REACT_APP_ENV === "DEVELOPMENT";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: growthbookClientKey,
  enableDevMode: isDevelopment,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

console.log(growthbook);

export default growthbook;
