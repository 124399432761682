import clsx from "clsx";
import moment from "moment";
import { TenantContext } from "../../../context/tenant-context";
import { useContext } from "react";
import FileTypeIcon from "../../Shared/FileTypeIcon";
import { Mixpanel } from "../../../common/mixpanel-common";

const HistoryItem: React.FC<{
  id: string;
  fileName: string;
  timestamp: number;
}> = (props) => {
  const ctx = useContext(TenantContext);
  const isDeleting = ctx.deletingSessionId === props.id && ctx.deleteInProgress;
  const isSelected = ctx.sessionId === props.id;

  const itemClicked = () => {
    ctx.setSelectedSessionId(props.id);
    ctx.setOpenDrawer(false);
    Mixpanel.track("HistoryItemClicked", {
      file_id: props.id,
      file_name: props.fileName,
    });
  };

  return (
    <tr
      className={clsx(
        { "bg-blue-500 bg-opacity-5": isSelected },
        { "opacity-25": isDeleting },
        "hover:shadow-soft-xs cursor-pointer",
      )}
    >
      <td
        className="py-3 align-middle bg-transparent border-b shadow-transparent"
        onClick={itemClicked}
      >
        <div className="flex flex-row px-0 md:px-2 py-1">
          <div className="h-8 w-8 mr-2 md:h-10 md:w-10">
            <FileTypeIcon id={props.id} fileName={props.fileName} />
          </div>
          <div className="flex-wrap justify-center w-full">
            <div className="mb-0 text-sm font-semibold word-break">
              {props.fileName}
            </div>
            <p className="mb-0 text-xs md:text-sm leading-tight text-slate-400">
              {moment(props.timestamp * 1000).fromNow()}
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default HistoryItem;
