const Deleting: React.FC<{}> = (props) => {
  return (
    <div className="lshaded my-4 px-4 py-20 border rounded-t-inherit rounded-xl sm:block">
      <div className="flex flex-col items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
        <span className="text-[#F000B8]">Deleting</span>
      </div>
    </div>
  );
};

export default Deleting;
