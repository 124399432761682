import mixpanel from "mixpanel-browser";

// init mixpanel if token is set
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
  });
}

let env_check = mixpanelToken?.length ?? 0;

let actions = {
  identify: (id: string) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name: string, props: any) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
