import React, { useEffect, useState } from "react";
import { ISharedJobItem } from "../types/FileSessionResponse";

interface Props {
  children: React.ReactNode;
}

type ExploreContextObj = {
  exploreData: ISharedJobItem[];
  selectedItemId: string;
  needReload: boolean;
  openDrawer: boolean;
  setExploreData: (items: ISharedJobItem[]) => void;
  addExploreData: (items: ISharedJobItem[]) => void;
  getSelectedItem: () => ISharedJobItem | undefined;
  setSelectedItemId: (id: string) => void;
  setNeedReload: (set: boolean) => void;
  setOpenDrawer: (set: boolean) => void;
};

export const ExploreContext = React.createContext<ExploreContextObj>({
  exploreData: [],
  selectedItemId: "",
  needReload: false,
  openDrawer: false,
  setExploreData: (items: ISharedJobItem[]) => {},
  addExploreData: (items: ISharedJobItem[]) => {},
  getSelectedItem: () => undefined,
  setSelectedItemId: (id: string) => {},
  setNeedReload: (set: boolean) => {},
  setOpenDrawer: (set: boolean) => {},
});

const ExploreContextProvider: React.FC<Props> = ({ children }) => {
  const [exploreData, setExploreData] = useState<ISharedJobItem[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<string>("");
  const [needReload, setNeedReload] = useState<boolean>(true);
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);

  const addExploreData = (items: ISharedJobItem[]) => {
    setExploreData([...exploreData, ...items]);
  };

  const getSelectedItem = () => {
    if (selectedItemId && exploreData) {
      return exploreData.find((item) => item?.jobId === selectedItemId);
    }
    return undefined;
  };

  useEffect(() => {
    setNeedReload(false);
  }, [exploreData]);

  const contextValue: ExploreContextObj = {
    exploreData: exploreData,
    selectedItemId: selectedItemId,
    needReload: needReload,
    openDrawer: openDrawer,
    setExploreData: setExploreData,
    addExploreData: addExploreData,
    getSelectedItem: getSelectedItem,
    setSelectedItemId: setSelectedItemId,
    setNeedReload: setNeedReload,
    setOpenDrawer: setOpenDrawer,
  };

  return (
    <ExploreContext.Provider value={contextValue}>
      {children}
    </ExploreContext.Provider>
  );
};

export default ExploreContextProvider;
